import React from 'react'
import { Link } from 'react-router-dom'
import { useAppSelector } from '../../hooks/appHook'
import Icon from '../../components/UI/Icons/Icons'

const Intro = () => {
  const { account } = useAppSelector((state) => state.account)

  return (
    <div id="buttons-container">
      <div className="buttons-group">
        <Link className="button" to="storage">
          <Icon name="addData" width={32} height={30} />
          <div className="button-text-wrapper">
            <h3>Knowledge Base</h3>
            <p>
              Add, search and edit the data in your Knowledge Base. Upload it by individual{' '}
              <span style={{ fontWeight: '700' }}>URL</span>, <span style={{ fontWeight: '700' }}>HTML</span> or{' '}
              <span style={{ fontWeight: '700' }}>text</span> options.
            </p>
          </div>
        </Link>
        <Link className="button" to="agent">
          <Icon name="createAssistent" width={28} height={27} />
          <div className="button-text-wrapper">
            <h3>AI Agent</h3>
            <p>Create and customize an AI Agent according to your business brand style.</p>
          </div>
        </Link>
      </div>
      <div className="buttons-group">
        <Link className="button" to="conversations">
          <Icon name="conversations" width={29} height={24} />
          <div className="button-text-wrapper">
            <h3>Conversations</h3>
            <p>
              Analyze the replies of your chatbot to the customers. Reply manually when needed and ioni will self-learn
              on the updated information.
            </p>
          </div>
        </Link>
        <Link className="button" to={`/c/${account.id}`} target="_blank">
          <Icon name="share" />
          <div className="button-text-wrapper">
            <h3>Test & share chat</h3>
            <p>Test how ioni answers the questions based on the information you added to database.</p>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default Intro
