import React from "react";
import SignIn from "./SignIn";

const SignUp = () => {
    return (
        <SignIn isSignup/>
    );
};

export default SignUp;
