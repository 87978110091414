import { useEffect } from 'react'
import { notification } from 'antd'

export const useWarningMessage = (message: string, isSuccess: boolean) => {
  useEffect(() => {
    if (isSuccess) {
      notification.warning({
        message,
      })
    }
  }, [isSuccess])
}
