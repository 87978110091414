import React, {FC} from "react";
import {Input, InputProps} from "antd";

import "./appAuthInput.less";
import Icon from "../Icons/Icons";

const AppAuthInput: FC<InputProps> = ({children, ...props}) => {

  return (
    <Input.Password
      className="app-auth-input-wrapper"
      iconRender={(visible) => (visible ? <Icon name={"inputPasswordEyeOpen"} /> : <Icon name={"inputPasswordEyeClosed"} />)}
      prefix={<Icon name={"inputLock"}/>}
      {...props}
    >
      {children}
    </Input.Password>
  );
};

export default AppAuthInput;
