import {Form, Space} from "antd";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import Loading from "../../components/UI/Loading";
import Icon from "../../components/UI/Icons/Icons";
import {useAppSelector} from "../../hooks/appHook";
import {PROFILE_EDIT_ROUTE} from "../../config/consts";
import {useErrorMessage} from "../../hooks/useErrorMessage";
import {useSuccessMessage} from "../../hooks/useSuccessMessage";
import {useInviteCreateSessionMutation} from "../../store/api/users.api";
import {useInviteSessionAndSetPasswordMutation} from "../../store/api/profile.api";
import AppAuthInputPassword from "../../components/UI/Input/AppAuthInputPassword";
import AppPrimaryButton from "../../components/UI/AppPrimaryButton/AppPrimaryButton";

const InviteCreateSession = () => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const code = params.get('code')
  const email = params.get('email')
  const [initialError, setInitialError] = useState<string>('')
  const { user } = useAppSelector((state) => state.profile)

  const [inviteCreateSession, { error: errorInvite, status: inviteStatus }] = useInviteCreateSessionMutation()
  const [setPassword, { error: errorUserUpdate, status: userUpdateStatus }] = useInviteSessionAndSetPasswordMutation()

  useErrorMessage(initialError, initialError || undefined)
  useErrorMessage('Something went wrong', errorInvite || errorUserUpdate)
  useSuccessMessage('Your password have been set', userUpdateStatus === 'fulfilled')

  const onFinishPasswordChange = (values: { password: string; confirmPassword: string }) => {
    setPassword({ ...values, id: user.id, passwordChange: true })
  }

  useEffect(() => {
    if (email && code) {
      inviteCreateSession({ email, code })
    } else {
      setInitialError('Email or code is invalid or code is expired')
    }
  }, [email, code])

  useEffect(() => {
    if (userUpdateStatus === 'fulfilled') {
      navigate(PROFILE_EDIT_ROUTE)
    }
  }, [userUpdateStatus])

  if (inviteStatus === 'pending') {
    return <Loading />
  }

  if (initialError) {
    return <div></div>
  }

  return (
    <div className="login-box">
      <Form name="login-form" onFinish={onFinishPasswordChange}>
        <p className="form-title">Great! You have been registered at ioni.ai.</p>
        <p>The only thing you need to do is set your new password. </p>

        <Form.Item
          name="password"
          rules={[{ required: true, min: 8, message: 'Too short password. Minimum 8 symbols!' }]}
          hasFeedback
        >
          <AppAuthInputPassword placeholder="Password" />
        </Form.Item>

        <Form.Item
          hasFeedback
          name="confirmPassword"
          dependencies={['password']}
          className="invite-create-session-form-item-confirm-password"
          rules={[
            ({ getFieldValue }) => ({
              required: true,
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('The two passwords that you entered do not match!'))
              },
            }),
          ]}
        >
          <AppAuthInputPassword placeholder="Confirm password" />
        </Form.Item>
        <Form.Item>
          <Space>
            <AppPrimaryButton
              size="large"
              type="primary"
              htmlType="submit"
              loading={userUpdateStatus === 'pending'}
              //className="login-form-button"
            >
              Set password
              <Icon name={'buttonRight'} />
            </AppPrimaryButton>
          </Space>
        </Form.Item>
      </Form>
    </div>
  )
}

export default InviteCreateSession
