import React, { useMemo, memo } from 'react'
import { useLocation } from 'react-router-dom'
import {
  ABOUT_IONI_ROUTE,
  AI_HELPER_EDIT_DATA_ROUTE,
  AI_HELPER_UPDATE_DATA_ROUTE,
  CREATE_AI_ASSISTENT,
  KNOWLEDGE_BASE,
} from '../../config/consts'
import { useAppSelector } from '../../hooks/appHook'
import './aiHelperHeader.less'

interface Props {
  title: string
}

const AiHelperHeader = ({ title }: Props) => {
  const { user } = useAppSelector((state) => state.profile)
  const location = useLocation()

  const paragraph = useMemo(() => {
    switch (location.pathname) {
      case AI_HELPER_UPDATE_DATA_ROUTE:
        return (
          <p>
            Here you may add any <span style={{ fontWeight: '700' }}>HTML</span> and
            <span style={{ fontWeight: '700' }}> text</span> pages to your AI database. Just copy and paste HTML or text
            from any page and ask AI helper with related questions.
          </p>
        )
      case ABOUT_IONI_ROUTE:
        return <p>Add some data and test how bot will reply to your questions.</p>
      case CREATE_AI_ASSISTENT:
        return <></>
      case KNOWLEDGE_BASE:
        return <></>
      case AI_HELPER_EDIT_DATA_ROUTE:
        return (
          <p>
            To find specific data that has been added to the database, enter the sentence you want to change in the
            search field and click the <span style={{ fontWeight: '700' }}>FIND DATA</span> button.
          </p>
        )
      default:
        return (
          <p>
            Check answers and adjust your Al Assistant. Add questions from your support requests, scheck the answer and
            edit if needed. ioni will learn and automatically improve auto-replies.
          </p>
        )
    }
  }, [location.pathname])

  return (
    <div className="ai-helper-header">
      {location.pathname === ABOUT_IONI_ROUTE ? (
        <h1>
          {title}, {user.name}
        </h1>
      ) : (
        <h1>{title}</h1>
      )}
      {paragraph}
    </div>
  )
}

export default memo(AiHelperHeader)
