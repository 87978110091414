import React, { useEffect, useRef, useState } from 'react'
import { Button, Input, InputRef, Space, Table } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { useGetAccountsMutation } from '../../store/api/admin/accounts.api'
import './admin.less'
import { LocalStorage } from 'ts-localstorage'
import { ADMIN_ACCOUNT_ID, ADMIN_USER_ID } from '../../config/consts'
import { ColumnType } from 'antd/es/table'
import type { ColumnsType } from 'antd/es/table/interface'
import { useErrorMessage } from '../../hooks/useErrorMessage'

interface DataType {
  createdAt: string
  updatedAt: string
  name: string
  status: string
  email: string
  conversations: any[]
}

type DataIndex = keyof DataType

const escapeRegExp = (string: string) => string.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&')

const Accounts = () => {
  const searchInput = useRef<InputRef>(null)

  const [emailSearching, setEmailSearching] = useState('')
  const [nameSearching, setNameSearching] = useState('')

  const [getAccounts, { data: dataSource, error }] = useGetAccountsMutation()

  useErrorMessage('Something went wrong!', error)

  useEffect(() => {
    getAccounts({ page: 1, limit: 10 })
  }, [])


  const handleSearch = (selectedKeys: string[], close: () => void, dataIndex: string) => {
    close()

    if (dataIndex === 'name') {
      setNameSearching(selectedKeys[0])
      getAccounts({
        page: 1,
        limit: 10,
        userMatch: emailSearching ? { email: { $regex: escapeRegExp(emailSearching), $options: 'i' } } : undefined,
        match: selectedKeys[0] ? { name: { $regex: escapeRegExp(selectedKeys[0]), $options: 'i' } } : undefined,
      })
    } else if (dataIndex === 'email') {
      setEmailSearching(selectedKeys[0])
      getAccounts({
        page: 1,
        limit: 10,
        userMatch: selectedKeys[0] ? { email: { $regex: escapeRegExp(selectedKeys[0]), $options: 'i' } } : undefined,
        match: nameSearching ? { name: { $regex: escapeRegExp(nameSearching), $options: 'i' } } : undefined,
      })
    }
  }

  const handleReset = (clearFilters: () => void, dataIndex: string) => {
    clearFilters()

    if (dataIndex === 'name') {
      setNameSearching('')
      getAccounts({
        page: 1,
        limit: 10,
        userMatch: emailSearching ? { email: { $regex: escapeRegExp(emailSearching), $options: 'i' } } : undefined,
      })
    } else if (dataIndex === 'email') {
      setEmailSearching('')
      getAccounts({
        page: 1,
        limit: 10,
        match: nameSearching ? { name: { $regex: escapeRegExp(nameSearching), $options: 'i' } } : undefined,
      })
    }
  }

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }}
          onPressEnter={() => handleSearch(selectedKeys as string[], close, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], close, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
    render: (text) => text,
  })

  const columns: ColumnsType<DataType> = [
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Updated at',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
    },
    {
      title: 'Account name',
      dataIndex: 'name',
      key: 'accountName',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Number of conversations',
      dataIndex: ['conversations', 'length'],
      key: 'numberOfConversation',
    },
    {
      title: 'Number of customers',
      dataIndex: ['users', 'length'],
      key: 'numberOfUsers',
    },
    {
      title: 'Customer email',
      dataIndex: ['users', 0, 'email'],
      key: 'userEmail',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Actions',
      dataIndex: '',
      render: (_: any, record: any) => {
        return (
          <Space>
            <a onClick={() => handleOnLogin(record.users[0].accountId, record.users[0]._id)}>Login</a>
          </Space>
        )
      },
    },
  ]
  const handlePageChange = (pageNumber: number) => {
    getAccounts({
      page: pageNumber,
      limit: 10,
      match: nameSearching ? { name: { $regex: escapeRegExp(nameSearching), $options: 'i' } } : undefined,
      userMatch: emailSearching ? { email: { $regex: escapeRegExp(emailSearching), $options: 'i' } } : undefined,
    })
  }

  const handleOnLogin = (accountId: string, userId: string) => {
    LocalStorage.setItem(ADMIN_USER_ID, userId)
    LocalStorage.setItem(ADMIN_ACCOUNT_ID, accountId)
    window.location.reload()
  }

  return (
    <div className="table-wrapper">
      <Table
        className="admin-table"
        dataSource={dataSource?.accounts}
        columns={columns}
        rowKey={'_id'}
        pagination={{
          total: dataSource?.total,
          onChange: handlePageChange,
          position: ['bottomCenter'],
        }}
        scroll={{ y: 400, x: 800 }}
      />
    </div>
  )
}

export default Accounts
