import React from "react";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ticketApi} from "../api/ticket.api";
import {messageApi} from "../api/message.api";
import {IMessage} from "../../types/message.type";
import {ITicket, TicketState} from "../../types/ticket.type";


export const initialState: TicketState = {
  tickets: [],
  pagination: {
    count: 0,
    currentPage: 1,
    rowsPerPage: 10
  },
  singleTicket: {
    id: 0,
    helpdeskId: "",
    userId: "",
    createdAt: "",
    helpdeskTicketAtAccountId: "",
    rawData: ""
  },
  filter: {
    priority: [],
    status: []
  },
  orderBy: "",
  orderDirection: "",
  messages: [],
  selectedRowKeys: []
};

export const ticketSlice = createSlice({
  name: "ticket",
  initialState,
  reducers: {
    setTickets: (state, action) => {
      state.tickets = action.payload;
    },
    setSelectedRowKeys: (state, action: PayloadAction<React.Key[]>) => {
      state.selectedRowKeys = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.pagination.currentPage = action.payload;
    },
    setRowsPerPage: (state, action: PayloadAction<number>) => {
      state.pagination.rowsPerPage = action.payload;
    },
    setSort: (state, action: PayloadAction<string>) => {
      if (state.orderBy === action.payload) {
        state.orderBy = initialState.orderBy;
      } else {
        state.orderBy = action.payload;
      }
    },
    setOrder: (state, action: PayloadAction<string>) => {
      if (state.orderDirection === action.payload) {
        state.orderDirection = initialState.orderDirection;
      } else {
        state.orderDirection = action.payload;
      }
    },
    setTicketsFilter: (state, action: PayloadAction<TicketState["filter"]>) => {
      state.filter = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(ticketApi.endpoints.getTickets.matchFulfilled, (state, action: PayloadAction<{ tickets: ITicket[], count: number }>) => {
        state.tickets = action.payload.tickets;
        state.pagination.count = action.payload.count;
      })
      .addMatcher(ticketApi.endpoints.getTicket.matchFulfilled, (state, action: PayloadAction<ITicket>) => {
        state.singleTicket = action.payload;
      })
      .addMatcher(ticketApi.endpoints.ticketUpdate.matchFulfilled, (state, action: PayloadAction<ITicket>) => {
        state.tickets = state.tickets.map((item) => {
          if (item.id !== action.payload.id) {
            return item;
          }
          return action.payload;
        });
      })
      .addMatcher(messageApi.endpoints.getMessages.matchFulfilled, (state, action: PayloadAction<IMessage[]>) => {
        state.messages = action.payload;
      })
  },
});

// export const { ticketResetState } = ticketSlice.actions;
export const ticketActionCreators = ticketSlice.actions;

export default ticketSlice.reducer;
