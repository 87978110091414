import { useEffect } from 'react'
import { notification } from 'antd'

export const useSuccessMessage = (message: string, isSuccess: boolean) => {
  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message,
      })
    }
  }, [isSuccess])
}
