import { createApi } from '@reduxjs/toolkit/query/react'
import { LocalStorage } from 'ts-localstorage'
import baseQueryWithReauth from '../baseQueryWithReauth'
import { Conversation, EditConversationRequest, ConversationStats } from '../../types/conversation.type'
import { ACCESS_TOKEN } from '../../config/consts'

export const conversationApi = createApi({
  reducerPath: 'conversationApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getConversationList: builder.query<Array<Conversation>, void>({
      query: () => ({
        url: `conversations`,
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
        headers: {
          authorization: 'Bearer ' + <string>LocalStorage.getItem(ACCESS_TOKEN),
        },
      }),
    }),
    getConversationStats: builder.query<ConversationStats, void>({
      query: () => ({
        url: `conversations/stats`,
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
        headers: {
          authorization: 'Bearer ' + <string>LocalStorage.getItem(ACCESS_TOKEN),
        },
      }),
    }),
    getConversationById: builder.query<Conversation, { id: string }>({
      query: ({ id }) => ({
        url: `conversations/${id}`,
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
        headers: {
          authorization: 'Bearer ' + <string>LocalStorage.getItem(ACCESS_TOKEN),
        },
      }),
    }),
    editConversation: builder.mutation<Conversation, EditConversationRequest>({
      query: ({ body, id }) => ({
        url: `conversations/${id}`,
        method: 'PATCH',
        body,
      }),
    }),
    deleteConversation: builder.mutation<void, string>({
      query: (id) => ({
        url: `conversations/${id}`,
        method: 'DELETE',
        credentials: 'include',
        mode: 'cors',
        headers: {
          authorization: 'Bearer ' + <string>LocalStorage.getItem(ACCESS_TOKEN),
        },
      }),
    }),
  }),
})

export const {
  useEditConversationMutation,
  useGetConversationListQuery,
  useGetConversationStatsQuery,
  useLazyGetConversationByIdQuery,
  useDeleteConversationMutation,
} = conversationApi
