import React, { useCallback, useEffect } from 'react'
import { Form, Modal } from 'antd'
import { useAppSelector } from '../../../../hooks/appHook'
import { useAppStateActions } from '../../../../hooks/actions'
import { useErrorMessage } from '../../../../hooks/useErrorMessage'
import { useSuccessMessage } from '../../../../hooks/useSuccessMessage'
import AppPrimaryButton from '../../../../components/UI/AppPrimaryButton/AppPrimaryButton'
import Icon from '../../../../components/UI/Icons/Icons'
import { useLocation, useNavigate } from 'react-router-dom'
import { useCreateAgentMutation } from '../../../../store/api/agent.api'
import { CreateAgentRequest } from '../../../../types/agent.type'
import CEAIAgentForm from '../CEAIAgentForm/CEAIAgentForm'

const CreateNewAIAgentModal = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { setIsCreateAIAgentModalOpen } = useAppStateActions()

  const [createAIAgentForm] = Form.useForm()

  /** Storage */
  const { isCreateAIAgentModalOpen } = useAppSelector((state) => state.appState.modals)

  /** Storage actions */
  const [
    createAIAgent,
    {
      error,
      isSuccess,
      isLoading,
      data,
    }] = useCreateAgentMutation()

  /** Response message handlers */
  useErrorMessage('Something went wrong.', error)
  useSuccessMessage('AI Agent was successfully created', isLoading)

  const onCompleteFormCreateAIAgent = useCallback((values: {name: string}) => {
    createAIAgent({body: values} as CreateAgentRequest)
  }, [])

  const onCancelFormCreateStorage = useCallback(() => {
    setIsCreateAIAgentModalOpen(false)
    createAIAgentForm.resetFields()
  }, [])

  useEffect(() => {
    if (isSuccess) {
      setIsCreateAIAgentModalOpen(false)
      createAIAgentForm.resetFields()
    }
  }, [isSuccess])

  useEffect(() => {
    if (data?.id) {
      const queryParams = new URLSearchParams(location.search);
      queryParams.set('agentId', data.id);

      /** Update the URL with the new query parameter, replacing the current entry without adding a new one to the history */
      navigate({ search: queryParams.toString() }, { replace: true });
    }
  }, [data])

  return (
    <Modal
      title="Create new AI agent"
      open={isCreateAIAgentModalOpen}
      footer={
        <AppPrimaryButton
          htmlType="submit"
          id="app-primary-button"
          loading={isLoading}
          onClick={createAIAgentForm.submit}
        >
          Create AI agent
          <Icon name="buttonRight" />
        </AppPrimaryButton>
      }
      onCancel={onCancelFormCreateStorage}
    >
      <div>
        <CEAIAgentForm
          aiAgentForm={createAIAgentForm}
          onSubmitForm={onCompleteFormCreateAIAgent}
        />
      </div>
    </Modal>
  )
}

export default CreateNewAIAgentModal
