import React from 'react'
import { UserAddOutlined } from '@ant-design/icons'
import { useAppStateActions } from '../../hooks/actions'
import { Button } from 'antd'

const InviteToTeamButton = () => {
  const { setIsInviteTeamModalOpen } = useAppStateActions()

  return (
    <Button id="invite-to-team-button" type="primary" onClick={() => setIsInviteTeamModalOpen(true)}>
      <UserAddOutlined />
      Invite to team
    </Button>
  )
}

export default InviteToTeamButton
