import React from 'react'
import Logo from '../../components/UI/Logos/Logo'
import './publicChat.less'
import { Button } from 'antd'
import TransparentButton from '../../components/UI/TransparentButton/TransparentButton'
import { useNavigate } from 'react-router-dom';



const ChatHeader = () => {


  const clearChat = () => {
    window.location.reload()
  }

const navigate = useNavigate()
  return (
    <div className="chat-header">
      <div id="chat-header-inner-container">
        <Button id='logo-btn' type="link">
          <Logo name={'safetyLensDefault'} width={'100%'} height={'100%'} />
        </Button>
        <div className="button-wrapper">
          <TransparentButton onClick={()=>navigate('/')} title='Home'/>
          <TransparentButton onClick={clearChat} title='New inspection'/>
        </div>
      </div>
    </div>
  )
}

export default ChatHeader
