import {createSlice} from "@reduxjs/toolkit";
import {MessageState} from "../../types/message.type";

export const initialState: MessageState = {
  messages: [],
};

export const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setMessages: (state, action) => {
      state.messages = action.payload;
    },
    messagesResetState: () => initialState
  },
  extraReducers: builder => {
    builder;
    // .addMatcher(ticketApi.endpoints.getTickets.matchFulfilled, (state, action) => {
    //     state.messages = action.payload.messages
    // })
    // .addMatcher(authApi.endpoints.logout.matchFulfilled, (state) => {
    //     state.messages = initialState.messages
    // })

  },
});

export const { messagesResetState } = messageSlice.actions;
export const messageActionCreators = messageSlice.actions;

export default messageSlice.reducer;
