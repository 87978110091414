import React from 'react'
import { ConfigProvider, Divider, Form, Input, List } from 'antd'
import TestChatButton from './TestChatButton'
import AppPrimaryButton from '../../../components/UI/AppPrimaryButton/AppPrimaryButton'
import SearchResultItem from '../../../components/UI/AISearchResultItem/AISearchResultItem'
import { useErrorMessage } from '../../../hooks/useErrorMessage'
import { useWarningMessage } from '../../../hooks/useWarningMessage'
import { requiredValidator } from '../../../helpers/validators'
import { useAppSelector } from '../../../hooks/appHook'
import {
  useCreateStorageKnowledgeRecordByStorageIdMutation,
  useDeleteStorageKnowledgeRecordByIdMutation,
  useLazyGetStorageKnowledgeRecordsBySearchParamsQuery,
} from '../../../store/api/storage.api'
import { StorageKnowledgeRecord } from '../../../types/storage.type'
import { RolesEnum } from '../../../config/rolesEnum'

const SearchAndEditTab = () => {
  const [searchForm] = Form.useForm()

  /** Storage */
  const { currentStorageId } = useAppSelector((state) => state.storage)
  const { user } = useAppSelector((state) => state.profile)

  /** Storage actions */
  const [getStorageKnowledgeRecords, getStorageKnowledgeRecordsQueryResult] =
    useLazyGetStorageKnowledgeRecordsBySearchParamsQuery()

  const [createStorageKnowledgeRecord, createStorageKnowledgeRecordMutationResult] =
    useCreateStorageKnowledgeRecordByStorageIdMutation()

  const [deleteStorageKnowledgeRecord, deleteStorageKnowledgeRecordMutationResult] =
    useDeleteStorageKnowledgeRecordByIdMutation()

  /** Response message handlers */
  useWarningMessage(
    'Sorry, we could not find such information in Knowledge Base. Please, try to use another search request.',
    getStorageKnowledgeRecordsQueryResult.isSuccess &&
      !getStorageKnowledgeRecordsQueryResult.isLoading &&
      !getStorageKnowledgeRecordsQueryResult.data?.documents.length,
  )
  useErrorMessage("Couldn't load data", getStorageKnowledgeRecordsQueryResult.error)
  useErrorMessage("Couldn't delete item", deleteStorageKnowledgeRecordMutationResult.error)
  useErrorMessage("Couldn't add item", createStorageKnowledgeRecordMutationResult.error)

  const isLoading =
    getStorageKnowledgeRecordsQueryResult.isLoading ||
    createStorageKnowledgeRecordMutationResult.isLoading ||
    deleteStorageKnowledgeRecordMutationResult.isLoading

  const handleOnSearch = (values: { query: string }) => {
    getStorageKnowledgeRecords({ id: currentStorageId, body: { query: values.query } })
  }

  const handleDeleteItem = async (item: StorageKnowledgeRecord) => {
    await deleteStorageKnowledgeRecord({
      id: currentStorageId,
      vectorId: item.id,
    })
    await getStorageKnowledgeRecords({
      id: currentStorageId,
      body: { query: searchForm.getFieldValue('query') },
    })
  }

  const handleEditData = async (value: string, recordId: string) => {
    await createStorageKnowledgeRecord({
      id: currentStorageId,
      body: {
        singleRecord: true,
        metadata: { source: 'edit_data_ui' },
        data: value,
      },
    })
    await deleteStorageKnowledgeRecord({ id: currentStorageId, vectorId: recordId })
    await getStorageKnowledgeRecords({ id: currentStorageId, body: { query: searchForm.getFieldValue('query') } })
  }

  console.log(getStorageKnowledgeRecordsQueryResult)

  return (
    <div>
      <div className="tab-header">
        <div className="header-description">
          <h2>Search & Edit Data</h2>
          <p>
            Here you may find and edit specific data that has been added to your Knowledge Base. Enter the sentence you
            want to change in the search field below and click “Search Data”.
          </p>
        </div>
        <TestChatButton />
      </div>
      <div className="edit-data-container">
        <Form form={searchForm} onFinish={handleOnSearch}>
          <Form.Item name="query" rules={[{ required: true, validator: requiredValidator('Required!') }]}>
            <Input size="large" />
          </Form.Item>
          <div className="search-buttons-container">
            <Form.Item>
              <AppPrimaryButton type="primary" htmlType="submit" className="app-primary-button-medium" size="middle">
                Search Data
              </AppPrimaryButton>
            </Form.Item>
          </div>
        </Form>
        {user?.role == RolesEnum.ADMIN &&
          getStorageKnowledgeRecordsQueryResult.data?.params &&
          JSON.stringify(getStorageKnowledgeRecordsQueryResult.data?.params)}
        <div className="custom-separator-list-container">
          <ConfigProvider renderEmpty={() => <></>}>
            <List
              pagination={{
                position: 'bottom',
                align: 'center',
                className: 'pagination',
              }}
              className="custom-separator-list"
              dataSource={getStorageKnowledgeRecordsQueryResult.data?.documents}
              split={false}
              loading={isLoading}
              renderItem={(item) => (
                <List.Item key={item.id}>
                  <Divider className="list-item-divider" />
                  <SearchResultItem item={item} handleDeleteItem={handleDeleteItem} handleEditData={handleEditData} />
                </List.Item>
              )}
            />
          </ConfigProvider>
        </div>
      </div>
    </div>
  )
}

export default SearchAndEditTab
