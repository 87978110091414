import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StorageKnowledgeRecord, StoragesState } from '../../types/storage.type'
import { storageApi } from '../api/storage.api'

export const initialState: StoragesState = {
  storages: [],
  storageKnowledgeRecords: [],
  editedKnowledgeRecords: [],
  addedKnowledgeRecords: [],
  editedKnowledgeRecordId: '',
  isSuccess: false,
  currentStorageId: ''
}

export const storageSlice = createSlice({
  name: 'storage',
  initialState,
  reducers: {
    setKnowledgeRecords: (state, action: PayloadAction<Array<StorageKnowledgeRecord>>) => {
      state.storageKnowledgeRecords = action.payload
    },
    setAddedKnowledgeRecords: (state, action: PayloadAction<Array<StorageKnowledgeRecord>>) => {
      state.addedKnowledgeRecords = action.payload
    },
    setEditedKnowledgeRecords: (state, action: PayloadAction<Array<StorageKnowledgeRecord>>) => {
      state.editedKnowledgeRecords = action.payload
    },
    setEditedKnowledgeRecordId: (state, action: PayloadAction<string>) => {
      state.editedKnowledgeRecordId = action.payload
    },
    setIsSuccess: (state, action: PayloadAction<boolean>) => {
      state.isSuccess = action.payload
    },
    setCurrentStorageId: (state, action: PayloadAction<string>) => {
      state.currentStorageId = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        storageApi.endpoints?.getStorageList.matchFulfilled,
        (state, action) => {
        state.storages = action.payload
      })
      .addMatcher(
        storageApi.endpoints?.getStorageKnowledgeRecordsBySearchParams.matchFulfilled,
        (state, action) => {
        state.storageKnowledgeRecords = action.payload.documents
      })
  },
})

export const {
  setKnowledgeRecords,
  setAddedKnowledgeRecords,
  setIsSuccess,
  setEditedKnowledgeRecords,
  setEditedKnowledgeRecordId,
  setCurrentStorageId
} = storageSlice.actions

export default storageSlice.reducer
