import { createApi } from '@reduxjs/toolkit/query/react'
import baseQueryWithReauth from '../baseQueryWithReauth'
import { Agent, CreateAgentRequest, EditAgentRequest } from '../../types/agent.type'
import { LocalStorage } from 'ts-localstorage'
import { ACCESS_TOKEN } from '../../config/consts'

export const agentApi = createApi({
  reducerPath: 'agentApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['GetAgentsList'],
  endpoints: (builder) => ({
    getAgentList: builder.query<Array<Agent>, void>({
      query: () => ({
        url: `agents`,
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
        headers: {
          authorization: 'Bearer ' + <string>LocalStorage.getItem(ACCESS_TOKEN),
        },
      }),
      providesTags: [{type: 'GetAgentsList'}]
    }),
    getAgentById: builder.query<Agent, { id: string }>({
      query: ({ id }) => ({
        url: `agents/${id}`,
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
        headers: {
          authorization: 'Bearer ' + <string>LocalStorage.getItem(ACCESS_TOKEN),
        },
      }),
    }),
    createAgent: builder.mutation<Agent, CreateAgentRequest>({
      query: ({ body }) => ({
        url: `agents`,
        method: 'POST',
        body,
      }),
      async onQueryStarted(_, {dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(agentApi.util.invalidateTags([{ type: 'GetAgentsList' }]))
        } catch (e) {
          console.log(e)
        }
      },
    }),
    editAgent: builder.mutation<Agent, EditAgentRequest>({
      query: ({ body, id }) => ({
        url: `agents/${id}`,
        method: 'PATCH',
        body,
      }),
      async onQueryStarted(_, {dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(agentApi.util.invalidateTags([{ type: 'GetAgentsList' }]))
        } catch (e) {
          console.log(e)
        }
      },
    }),
    deleteAgent: builder.mutation<void, string>({
      query: (id) => ({
        url: `agents/${id}`,
        method: 'DELETE',
        credentials: 'include',
        mode: 'cors',
        headers: {
          authorization: 'Bearer ' + <string>LocalStorage.getItem(ACCESS_TOKEN),
        },
      }),
      async onQueryStarted(_, {dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(agentApi.util.invalidateTags([{ type: 'GetAgentsList' }]))
        } catch (e) {
          console.log(e)
        }
      },
    }),
  }),
})

export const {
  useGetAgentListQuery,
  useCreateAgentMutation,
  useLazyGetAgentByIdQuery,
  useEditAgentMutation,
  useDeleteAgentMutation,
} = agentApi
