import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { Table, Tag } from 'antd'
import { useGetConversationListQuery } from '../../store/api/conversation.api'
import './conversations.less'

const columns = [
  {
    title: 'Created at',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (value: string, record: any) => {
      const formatDate = moment(value).format('D/MM/YYYY')
      const formatTime = moment(value).format('h:mm a')
      return (
        <Link to={`${record.id}`} className="conversation-url">
          <p>
            {formatDate} <span>{formatTime}</span>
          </p>
          {record.unread && record.unread === true && (
            <Tag color="green" style={{ height: 22 }}>
              New
            </Tag>
          )}
        </Link>
      )
    },
  },
  {
    title: 'Satisfactory',
    dataIndex: ['analytics', 'satisfactory'],
    key: 'satisfactory',
  },
  {
    title: 'Info found',
    dataIndex: ['analytics', 'infoFounded'],
    key: 'infoFounded',
  },
  {
    title: 'User name',
    dataIndex: 'userName',
    key: 'userName',
  },
  {
    title: 'Customer name',
    dataIndex: 'customerName',
    key: 'customerName',
  },
  {
    title: 'Customer email',
    dataIndex: 'customerEmail',
    key: 'customerEmail',
  },
  {
    title: 'Messages count',
    dataIndex: ['messagesCount'],
    key: 'messagesCount',
  },
]

const Conversations = () => {
  const { data: dataSource, isLoading } = useGetConversationListQuery(undefined, {
    refetchOnMountOrArgChange: true,
  })

  return (
    <div id="container">
      <Table
        id="table"
        dataSource={dataSource}
        columns={columns}
        rowKey={'id'}
        loading={isLoading}
        pagination={{ position: ['bottomCenter'] }}
        scroll={{ y: 400, x: 800 }}
      />
    </div>
  )
}

export default Conversations
