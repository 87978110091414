export const theme = {
	token: {
		colorPrimary: '#7169FB',
		colorOppositePrimary: '#FFFFFF',
		colorPrimaryActive: '#2d4ffc',
		colorPrimaryHover: 'rgba(72, 102, 255, 0.8)',
		colorLink: '#7169FB',
		colorLinkActive: '#2d4ffc',
		colorLinkHover: 'rgba(72, 102, 255, 0.8)',
		colorError: '#cf5d60',
		fontFamily: `'Roboto', Arial, sans-serif`,
		colorBgContainer: '#fff',
		colorTextHeading: '#0A2540',
	},
	components: {
		Tooltip: {
			fontSize: 12
		},
		Table: {
			colorBorderSecondary: '#7169fb33',
			colorText: '#0A2540',
			colorBgContainer: 'transparent'
		},
		Modal: {
      colorText: '#0A2540',
			colorBgMask: 'rgba(0,0,0, 0.1)',
			colorPrimaryBorder: 'rgba(10,37,64,.1)',
			borderRadiusLG: 20,
			boxShadow: '0 20px 30px rgba(0,0,0,.05)',
		},
		Checkbox: {
			borderRadiusSM: 10
		}
	}
}
