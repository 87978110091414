import { createApi } from '@reduxjs/toolkit/query/react'
import baseQueryWithReauth from '../../baseQueryWithReauth'
import { LocalStorage } from 'ts-localstorage'
import { ACCESS_TOKEN } from '../../../config/consts'

export const accountsApi = createApi({
  reducerPath: 'accountsApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAccounts: builder.mutation({
      query: (body) => ({
        url: `admin/accounts/lookup`,
        method: 'POST',
        body,
        credentials: 'include',
        mode: 'cors',
        headers: {
          authorization: 'Bearer ' + <string>LocalStorage.getItem(ACCESS_TOKEN),
        },
      }),
    }),
    getAccountOwner: builder.query<{userId: string}, string>({
      query: (accountId) => ({
        url: `admin/accounts/owner/${accountId}`,
        method: 'GET',
      })
    })
  }),
})

export const { useGetAccountsMutation, useLazyGetAccountOwnerQuery } = accountsApi

