import React from 'react'
import './homepage.less'
import Intro from './Intro'
import { useGetConversationStatsQuery } from '../../store/api/conversation.api'
import { Spin } from 'antd'
import Statistics from './Statistics'

const Homepage = () => {
  const { data, isLoading } = useGetConversationStatsQuery()

  if (isLoading) {
    return <Spin size={'large'} />
  }

  return data?.count ? <Statistics data={data} /> : <Intro />
}

export default Homepage
