import React from "react";
import {Button, ButtonProps} from "antd";

import "./appPrimaryButton.less";

// interface Props extends ButtonProps {
//   iconRight?: React.ReactNode;
// }

const AppPrimaryButton = ({ children, ...props }: ButtonProps) => {
  return (
    <Button
      id="app-primary-button"
      {...props}
    >
      {children}
    </Button>
  );
};

export default AppPrimaryButton;
