import { Button } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import React from 'react'

interface IProps {
  images: Array<File | string>;
  removeImage: (imgIndex: number) => void;
}

const SelectedImages = ({ images, removeImage }: IProps) => {
  return <div className="image-previews">
    {images.map((image, index) => (
      <div key={index} className="image-preview">
        <img
          src={typeof image === 'string' ? image : URL.createObjectURL(image)} alt={`preview-${index}`}
          style={{ width: 'auto', height: 'auto', maxHeight: '60px' }}
          className="added-image-preview"
        />
        <Button
          className="remove-preview-image-button"
          icon={<DeleteOutlined style={{ fontSize: '10px', color: '#FFFFFF' }} />}
          onClick={() => removeImage(index)}
        />
      </div>
    ))}
  </div>
}

export default SelectedImages
