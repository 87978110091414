import React, {memo} from 'react'
import { Form, FormInstance, Input } from 'antd'
import { requiredValidator } from '../../../../helpers/validators'
import './ceAIAgentForm.less'

interface IProps {
  aiAgentForm: FormInstance
  onSubmitForm: (values: {name: string}) => void
}
const CEAIAgentForm = ({aiAgentForm, onSubmitForm}: IProps) => {
  return <Form
    name="create_edit_ai_agent_form"
    form={aiAgentForm}
    layout="vertical"
    className="create-ai-agent-form"
    onFinish={onSubmitForm}
  >
    <Form.Item
      label="Name"
      name="name"
      rules={[{ required: true, validator: requiredValidator('Name is required!') }]}
      style={{ marginBottom: '8px' }}
    >
      <Input />
    </Form.Item>
  </Form>
}

export default memo(CEAIAgentForm)
