import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { accountApi } from '../api/account.api'
import { AccountState, AccountStatusEnum, IAccount, SubscriptionPlan } from '../../types/account.type'

export const initialState: AccountState = {
  account: {
    id: '',
    name: '',
    hasStripe: false,
    data: {},
    status: AccountStatusEnum.TRIAL,
    plan: SubscriptionPlan.BASIC
  },
  isIntegrationActionMade: false,
}

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setAccount: (state, action: PayloadAction<IAccount>) => {
      state.account = action.payload
    },
    setIntegrationActionMade: (state, action: PayloadAction<boolean>) => {
      state.isIntegrationActionMade = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(accountApi.endpoints.accountUpdate.matchFulfilled, (state, action: PayloadAction<IAccount>) => {
        state.account = action.payload
      })
      .addMatcher(accountApi.endpoints.getAccount.matchFulfilled, (state, action: any) => {
        state.account = action.payload.account
      })
  },
})

export const accountActionCreators = accountSlice.actions

export default accountSlice.reducer
