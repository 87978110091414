import React, { memo, useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom';
import { first } from 'lodash'
import { useAppSelector } from '../../../../hooks/appHook'
import AiHelperHeader from '../../../../components/AIHelperHeader/aiHelperHeader'
import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { useAppStateActions } from '../../../../hooks/actions'
import CustomSelect from '../../../../components/CustomSelect/CustomSelect'
import { useGetAgentListQuery } from '../../../../store/api/agent.api'
import CreateNewAIAgentModal from '../CreateNewAIAgentModal/CreateNewAIAgentModal'
import { setCurrentAgentId } from '../../../../store/slices/agents.slice'

const QUERY_AGENT_ID_NAME = 'agentId'

const AIAgentPageHeader = ({title}: {title: string}) => {
  const dispatch = useDispatch()
  const locate = useLocation()
  const navigate = useNavigate()

  const { setIsCreateAIAgentModalOpen } = useAppStateActions()

  /** Storage */
  const { account } = useAppSelector((state) => state.account)

  /** Storage actions */
  const {data: agents} = useGetAgentListQuery()

  const queryParams = new URLSearchParams(locate.search);

  const firstAgentInAgents = useMemo(() => {
    return first(agents) || null
  }, [agents])

  const selectOptions = useMemo(() => {
    return agents?.map(({id, name}) => {
      return {
        value: id,
        label: id === account.defaultAgentId ? `${name} (default)` :name
      }
    }) || []
  }, [agents, account])

  const queryParamId = useMemo(() => {
    const queryId = queryParams.get(QUERY_AGENT_ID_NAME)
    if (queryId && selectOptions.map(({value}) => value).includes(queryId)) {
      return queryId
    }
    return firstAgentInAgents?.id || ''
  }, [queryParams, firstAgentInAgents, selectOptions])

  useEffect(() => {
    dispatch(setCurrentAgentId(queryParamId))
  }, [queryParamId])

  useEffect(() => {
    /** If there is a first element in the list of agents (firstAgentInAgents)
     and the URL does not have a query parameter named QUERY_AGENT_ID_NAME */
    if (firstAgentInAgents && !queryParams.has(QUERY_AGENT_ID_NAME)) {
      /** Set the QUERY_AGENT_ID_NAME parameter with the ID of the first element in the list of storages */
      queryParams.set(QUERY_AGENT_ID_NAME, firstAgentInAgents.id);

      /** Update the URL with the new query parameter, replacing the current entry without adding a new one to the history */
      navigate({ search: queryParams.toString() }, { replace: true });
    }
  }, [queryParams, firstAgentInAgents]);

  const onChangeSelect = useCallback((value: any) => {
    queryParams.set(QUERY_AGENT_ID_NAME, value);
    navigate({ search: queryParams.toString() }, { replace: true });
    dispatch(setCurrentAgentId(value))
  }, [])

  const onPressCreateAiAgent = useCallback(() => {
    setIsCreateAIAgentModalOpen(true)
  }, [])


  return (
    <div className="page-header">
      <CreateNewAIAgentModal />
      <AiHelperHeader title={title} />
      <div className="header-right-content">
        {agents?.length
          ? <CustomSelect
            createButtonTitle="Create AI Agent"
            onChange={onChangeSelect}
            options={selectOptions}
            initialStatus={queryParamId}
            key={queryParamId}
            onPressCreteButton={onPressCreateAiAgent}
          />
          : <Button
            type="text"
            icon={<PlusOutlined />}
            onClick={onPressCreateAiAgent}
            className="create-storage-button"
          >
            Create new agent
          </Button>}
      </div>
    </div>
  )
}

export default memo(AIAgentPageHeader)
