import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppState } from '../../types/appState.type'

export const initialState: AppState = {
  appState: {
    accessTokenExpireTimeInSeconds: 3600,
  },
  modals: {
    isInviteToTeamModalOpen: false,
    isCreateStorageModalOpen: false,
    isAddImageByUrlModalOpen: false,
    isCreateAIAgentModalOpen: false,
    isOnBoardingInfoModalOpen: false,
    isIntegrationEmailModalOpen: false,
    isIntegrationTooltipOpen: true,
  },
}

export const appStateSlice = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.appState = action.payload
    },
    setIsInviteTeamModalOpen: (state, action: PayloadAction<boolean>) => {
      state.modals.isInviteToTeamModalOpen = action.payload
    },
    setAddImageByUrlModalOpen: (state, action: PayloadAction<boolean>) => {
      state.modals.isAddImageByUrlModalOpen = action.payload
    },
    setIsCreateStorageModalOpen: (state, action: PayloadAction<boolean>) => {
      state.modals.isCreateStorageModalOpen = action.payload
    },
    setIsCreateAIAgentModalOpen: (state, action: PayloadAction<boolean>) => {
      state.modals.isCreateAIAgentModalOpen = action.payload
    },
    setIsOnboardingInfoModalOpen: (state, action: PayloadAction<boolean>) => {
      state.modals.isOnBoardingInfoModalOpen = action.payload
    },
    setIsIntegrationEmailModalOpen: (state, action: PayloadAction<boolean>) => {
      state.modals.isIntegrationEmailModalOpen = action.payload
    },
    setIsIntegrationTooltipOpen: (state, action: PayloadAction<boolean>) => {
      state.modals.isIntegrationTooltipOpen = action.payload
    },
  },
})

export const appStateActionCreators = appStateSlice.actions

export default appStateSlice.reducer
