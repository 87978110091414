import { Form, Space, Steps } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import TopFloater from '../guest/TopFloater'
import Icon from '../../components/UI/Icons/Icons'
import { useAppSelector } from '../../hooks/appHook'
import '../../layouts/guestLayout/guestLayout.less'
import { useOnboardingActions } from '../../hooks/actions'
import { useErrorMessage } from '../../hooks/useErrorMessage'
import Logo, { ILogoProps } from '../../components/UI/Logos/Logo'
import AppAuthInput from '../../components/UI/Input/AppAuthInput'
import { cxmSystems, operatorValues } from '../../store/slices/onboardingSlice'
import AppPrimaryButton from '../../components/UI/AppPrimaryButton/AppPrimaryButton'
import { useAccountUpdateMutation, useGetAccountQuery } from '../../store/api/account.api'
import './onboarding.less'
import { requiredValidator } from '../../helpers/validators'
import { useSuccessMessage } from '../../hooks/useSuccessMessage'

const steps = [
  {
    title: 'What is your company name?',
  },
  {
    title: 'Choose your preferred CXM system',
  },
  {
    title: 'How many service operators will be connected to ioni platform?',
  },
]

const Onboarding = () => {
  const navigate = useNavigate()
  const [stepsForm] = Form.useForm()
  const [currentStep, setCurrentStep] = useState(0)
  const { user } = useAppSelector((state) => state.profile)
  const { onboarding } = useAppSelector((state) => state.onboarding)
  const { setCxmSystem, setCxmCustomSystem, setNumberOfOperators, setName } = useOnboardingActions()

  const { error, isLoading: isFetching } = useGetAccountQuery(undefined, { skip: !user.id })
  const [updateAccount, updAccResp] = useAccountUpdateMutation()

  useErrorMessage('Something went wrong', updAccResp.error || error)
  useSuccessMessage(
    'Thank you for registration. Please check your email inbox for further instructions.',
    updAccResp.status === 'fulfilled',
  )

  const onFinishStep = () => {
    if (currentStep === 2) {
      updateAccount({
        name: onboarding.name,
        data: {
          domain: onboarding.domain.trim(),
          cxmSystem: onboarding.cxmSystem.title.trim(),
          cxmCustomSystem: onboarding.cxmCustomSystem.trim(),
          numberOfOperators: onboarding.numberOfOperators.trim(),
        },
      })
      return
    }
    setCurrentStep((prevState) => prevState + 1)
  }

  useEffect(() => {
    if (updAccResp.status === 'fulfilled') {
      navigate('/')
    }
  }, [updAccResp.status])

  // reset cxmSystem cards state to default
  useEffect(() => {
    if (onboarding.cxmCustomSystem) {
      setCxmSystem({ title: '', image: '' })
    }
  }, [onboarding.cxmCustomSystem])

  // empty custom cxm input field
  useEffect(() => {
    if (onboarding.cxmSystem.title.length > 0) {
      setCxmCustomSystem('')
    }
  }, [onboarding.cxmSystem])

  const isLoading = isFetching || updAccResp.isLoading

  return (
    <>
      <TopFloater isSignup />
      <div className="poll-box">
        <div className="poll-wrapper">
          <div className="poll-title">
            <h2 className="poll-title-label">
              Let&apos;s get to know you, <span className="poll-title-label-username">{user.name}</span>
            </h2>
            <p className="poll-text">
              We are eager to learn more about you to help you have the best experience with ioni
            </p>
          </div>
          <Steps
            type={'default'}
            current={currentStep}
            responsive={false}
            progressDot={(iconDot, { status }) => {
              if (status === 'wait') {
                return <span className="wait-line"></span>
              }
              if (status === 'process' || status === 'finish') {
                return <span className="progress-line"></span>
              }
            }}
            items={[0, 1, 2].map((step) => ({ key: step, onClick: () => undefined }))}
          />
          <Form
            className="poll-form"
            name="poll-form"
            form={stepsForm}
            autoComplete="off"
            requiredMark={false}
            onFinish={onFinishStep}
          >
            <div className="poll-content">
              <div className="poll-content-title">{steps[currentStep].title}</div>
              {currentStep === 0 && (
                <Form.Item
                  name="companyName"
                  rules={[
                    {
                      required: true,
                      validator: requiredValidator('Please input your company name'),
                    },
                  ]}
                >
                  <AppAuthInput
                    placeholder="Company"
                    style={{ minWidth: 490 }}
                    value={onboarding.name}
                    prefix={<Icon name="copyright" />}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Item>
              )}
              {currentStep === 1 && (
                <div className="poll-content-cards-wrapper">
                  {cxmSystems.map((system) => (
                    <Logo
                      key={system.title}
                      name={system.title as ILogoProps['name']}
                      onClick={() => {
                        setCxmSystem(system)
                        stepsForm.setFieldsValue({ selectedSystem: system.title })
                      }}
                      className={
                        'poll-cxm-card ' + (system.title === onboarding.cxmSystem.title ? 'poll-cxm-card-selected' : '')
                      }
                    >
                      {system.title === onboarding.cxmSystem.title && (
                        <Icon name="checkWhite" className="poll-cxm-card-selected-icon"></Icon>
                      )}
                    </Logo>
                  ))}
                  <Form.Item
                    name="selectedSystem"
                    rules={[
                      {
                        validator: requiredValidator('Please select or enter system name'),
                      },
                    ]}
                    className={'form-item-action-button'}
                  >
                    <AppAuthInput
                      prefix={<Icon name="courthouse" />}
                      value={onboarding.cxmCustomSystem}
                      style={{ marginTop: 8, minWidth: 579 }}
                      onChange={(e) => setCxmCustomSystem(e.target.value)}
                      placeholder="Write the name manually if you do not find your system"
                    />
                  </Form.Item>
                </div>
              )}

              {currentStep === 2 && (
                <div className="poll-content-cards-wrapper">
                  {operatorValues.map((item) => (
                    <div
                      key={item.value}
                      onClick={() => setNumberOfOperators(item.value)}
                      className={
                        'poll-cxm-card ' + (item.value === onboarding.numberOfOperators ? 'poll-cxm-card-selected' : '')
                      }
                    >
                      {item.value === onboarding.numberOfOperators && (
                        <Icon name="checkWhite" className="poll-cxm-card-selected-icon"></Icon>
                      )}
                      {item.label}
                    </div>
                  ))}
                  {/*<Select*/}
                  {/*  defaultValue={operatorValues[0].value}*/}
                  {/*  style={{ width: 400 }}*/}
                  {/*  options={operatorValues}*/}
                  {/*  onChange={setNumberOfOperators}*/}
                  {/*/>*/}
                  {/*{onboarding.cxmSystem.title === 'Freshdesk-temporary-disabled' && (*/}
                  {/*  <>*/}
                  {/*    <div>Fill in your API key:</div>*/}
                  {/*    <Form.Item>*/}
                  {/*      <Input*/}
                  {/*        placeholder="your API key here"*/}
                  {/*        value={onboarding.apiKey}*/}
                  {/*        style={{ width: 400 }}*/}
                  {/*        onChange={(e) => setApiKey(e.target.value)}*/}
                  {/*      />*/}
                  {/*    </Form.Item>*/}
                  {/*    <div>Domain name (xxxx.freshdesk.com):</div>*/}
                  {/*    <Form.Item>*/}
                  {/*      <Input*/}
                  {/*        placeholder="fill in domain"*/}
                  {/*        value={onboarding.domain}*/}
                  {/*        style={{ width: 400 }}*/}
                  {/*        onChange={(e) => setDomain(e.target.value)}*/}
                  {/*        suffix=".freshdesk.com"*/}
                  {/*      />*/}
                  {/*    </Form.Item>*/}
                  {/*  </>*/}
                  {/*)}*/}
                </div>
              )}
            </div>
            <Space size={10} className="form-item-action-button">
              {currentStep !== 0 && (
                <AppPrimaryButton
                  size="large"
                  type="primary"
                  onClick={() => setCurrentStep((prev) => prev - 1)}
                  loading={isLoading}
                  className={'onboarding-back-btn'}
                >
                  <Icon name={'buttonRight'} />
                  Back
                </AppPrimaryButton>
              )}

              <AppPrimaryButton size="large" type="primary" htmlType="submit" loading={isLoading}>
                {currentStep === 2 ? 'Finish' : 'Next'}
                <Icon name={'buttonRight'} />
              </AppPrimaryButton>
            </Space>
          </Form>
        </div>
      </div>
    </>
  )
}

export default Onboarding
