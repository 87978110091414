import React from 'react'
import TestChatButton from './TestChatButton'
import { Tabs, TabsProps } from 'antd'
import AddHtmlOrTextTab from './AddHtmlOrTextTab'
import AddByUrlTab from './AddByUrlTab'

const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Add data in HTML or Text',
      children: <AddHtmlOrTextTab />,
    },
    {
      key: '2',
      label: 'Add data by individual URL',
      children: <AddByUrlTab />,
    },
  ]

const AddNewDataTab = () => {
  return (
    <div className='add-new-data-tab'>
      <div className="tab-header">
        <div className="header-description">
          <h2>Add New Data</h2>
          <p>
            Here you may add any HTML and text pages to your Knowledge Base. You may also index an individual URL from
            your website or landing page.
          </p>
        </div>
        <TestChatButton />
      </div>
      <Tabs defaultActiveKey="1" items={items} destroyInactiveTabPane />
    </div>
  )
}

export default AddNewDataTab
