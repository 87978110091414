import React from 'react'
import { useLocation } from 'react-router-dom'
import { ADMIN_USERS_ROUTE } from '../../config/consts'
import InviteToTeamButton from '../../routes/admin/InviteToTeamButton'
import { useAppSelector } from '../../hooks/appHook'
import { AccountRolesEnum } from '../../config/rolesEnum'

const RightHeader = () => {
  const location = useLocation()
  const { user } = useAppSelector((state) => state.profile)

  const header = [
    {
      path: ADMIN_USERS_ROUTE,
      render: user.accountRole === AccountRolesEnum.ADMIN && <InviteToTeamButton />,
    },
    // {
    //     path: PROFILE_ROUTE,
    //     render: <Space><ProfileOutlined/>My Profile</Space>
    // },
    // {
    //     path: DASHBOARD_ROUTE,
    //     render: <Space><DashboardOutlined/>Dashboard</Space>
    // },
    // {
    //     path: TICKETS_ROUTE,
    //     render: <Space><DatabaseOutlined/>Tickets</Space>
    // },
    // {
    //     path: ADMIN_INVITE_ROUTE,
    //     render: <Space><UsergroupAddOutlined/>Invite to team</Space>
    //}
  ]

  return <>{header.find((item) => location.pathname.includes(item.path))?.render}</>
}

export default RightHeader
