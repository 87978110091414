import {Spin} from "antd";
import React, {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useVerifyEmailMutation} from "../../store/api/auth.api";
import {PROFILE_ROUTE} from "../../config/consts";
import {useErrorMessage} from "../../hooks/useErrorMessage";
import {useSuccessMessage} from "../../hooks/useSuccessMessage";

const Auth = () => {
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const navigate = useNavigate();
    const [verifyEmail, { status: verifyEmailStatus, error: errorVerifyEmail }] = useVerifyEmailMutation();
    const email = params.get("email");
    const code = params.get("code");
    const userId = params.get("userId");

    useErrorMessage("Something went wrong", errorVerifyEmail);
    useSuccessMessage("Your email was successfully verified!", verifyEmailStatus === 'fulfilled')

    useEffect(() => {
        if (email && code) {
            verifyEmail({ email, code, ...(userId && {userId}) });
        }
    }, [email, code]);

    useEffect(() => {
        if (verifyEmailStatus === 'fulfilled') {
            navigate(PROFILE_ROUTE);
        }
    }, [verifyEmailStatus]);

    return <Spin size={"large"}/>;
};

export default Auth;
