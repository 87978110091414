import { RuleObject } from "antd/es/form";
import {isBoolean} from "lodash";

export const requiredValidator = (message: string) => (_: RuleObject, value: string | boolean) => {
    if (!isBoolean(value) && (value === null || value === undefined || value?.trim() === '')) {
        return Promise.reject(new Error(message))
    }
    return Promise.resolve()
}

export const minLengthValidator =
    (min: number, field: string) => (_: RuleObject, value: string) => {
        if (!value) {
            return Promise.resolve()
        }

        return value.trim()?.length < min
            ? Promise.reject(new Error(`${field} should contain at least ${min} characters!`))
            : Promise.resolve()
    }

export const whitespaceValidator = (fieldName: string) => (_: RuleObject, value: unknown) => {
    if (typeof value === 'string' && value !== '' && value.trim() === '') {
        return Promise.reject(new Error(`${fieldName} should not have whitespaces!`));
    }
    return Promise.resolve();
}
