import React, { useEffect } from 'react'
import { Form, Modal } from 'antd'
import Icon from '../Icons/Icons'
import AppAuthInput from '../Input/AppAuthInput'
import { useAppSelector } from '../../../hooks/appHook'
import { useAppStateActions } from '../../../hooks/actions'
import { useCreateUserMutation } from '../../../store/api/users.api'
import { useErrorMessage } from '../../../hooks/useErrorMessage'
import { useSuccessMessage } from '../../../hooks/useSuccessMessage'
import AppPrimaryButton from '../AppPrimaryButton/AppPrimaryButton'
import {requiredValidator} from "../../../helpers/validators";

const InviteToTeamModal = () => {
  const [formInviteToTeam] = Form.useForm()
  const [sendInvite, { error: errorSendInvite, isSuccess: isSuccessSendInvite, isLoading: isSendingInvite }] =
    useCreateUserMutation()
  const { setIsInviteTeamModalOpen } = useAppStateActions()
  const { isInviteToTeamModalOpen } = useAppSelector((state) => state.appState.modals)

  const onFinishFormInviteToTeam = (values: { email: string }) => {
    sendInvite(values)
  }

  useEffect(() => {
    if (isSuccessSendInvite) {
      setIsInviteTeamModalOpen(false)
    }
  }, [isSuccessSendInvite])

  useErrorMessage('Invalid credentials', errorSendInvite)
  useSuccessMessage('Invitation email with instructions have been sent', isSuccessSendInvite)

  return (
    <Modal
      title="Invite to team"
      open={isInviteToTeamModalOpen}
      footer={
        <AppPrimaryButton
          htmlType="submit"
          id="app-primary-button"
          loading={isSendingInvite}
          onClick={() => formInviteToTeam.submit()}
        >
          Send invitation
          <Icon name="buttonRight" />
        </AppPrimaryButton>
      }
      onCancel={() => {
        setIsInviteTeamModalOpen(false)
        formInviteToTeam.resetFields()
      }}
    >
      <div>
        <h2>Want someone to join your team?</h2>
        <p>
          It&apos;s a simple. Send invitation to your friend or collaborator via email and Ioni will create account for
          them automatically after invitation have been applied.{' '}
        </p>
        <Form
          name="normal_login"
          form={formInviteToTeam}
          className="login-form"
          //initialValues={{ remember: true }}
          style={{ padding: '20px 0' }}
          onFinish={onFinishFormInviteToTeam}
        >
          <Form.Item name="email" rules={[{ type: 'email', message: 'Please input valid Email!' }, {required: true, validator: requiredValidator('Email is required!')}]}>
            <AppAuthInput prefix={<Icon name={'inputSms'} />} placeholder="Email" />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  )
}

export default InviteToTeamModal
