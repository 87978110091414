import React, { useEffect, useMemo } from 'react'
import { Divider, List } from 'antd'
import SearchResultItem from '../../../components/UI/AISearchResultItem/AISearchResultItem'
import { useErrorMessage } from '../../../hooks/useErrorMessage'
import TestChatButton from './TestChatButton'
import { useAppSelector } from '../../../hooks/appHook'
import {
  useCreateStorageKnowledgeRecordByStorageIdMutation,
  useDeleteStorageKnowledgeRecordByIdMutation, useLazyGetStorageKnowledgeRecordsByIdQuery,
} from '../../../store/api/storage.api'
import { StorageKnowledgeRecord } from '../../../types/storage.type'

const ReviewAllDataTab = () => {

  /** Storage */
  const { currentStorageId } = useAppSelector((state) => state.storage)

  /** Storage Actions */
  const [
    getStorageKnowledgeRecords,
    getStorageKnowledgeRecordsQueryResult
  ] = useLazyGetStorageKnowledgeRecordsByIdQuery()

  const [
    createStorageKnowledgeRecord,
    createStorageKnowledgeRecordMutationResult
  ] = useCreateStorageKnowledgeRecordByStorageIdMutation()

  const [
    deleteStorageKnowledgeRecord,
    deleteStorageKnowledgeRecordMutationResult
  ] = useDeleteStorageKnowledgeRecordByIdMutation()

  useErrorMessage(
    'Server error!',
    getStorageKnowledgeRecordsQueryResult.error ||
    createStorageKnowledgeRecordMutationResult.error ||
    deleteStorageKnowledgeRecordMutationResult.error,
  )

  useEffect(() => {
    getStorageKnowledgeRecords({id: currentStorageId})
  }, [currentStorageId])

  const handleDeleteItem = async (item: StorageKnowledgeRecord) => {
    await deleteStorageKnowledgeRecord({id: currentStorageId, vectorId: item.id})
    await getStorageKnowledgeRecords({id: currentStorageId})
  }

  const handleEditData = async (value: string, recordId: string) => {
    await createStorageKnowledgeRecord({
      id: currentStorageId,
      body: {
        singleRecord: true,
        metadata: { source: 'edit_data_ui' },
        data: value
      }
    })
    await deleteStorageKnowledgeRecord({id: currentStorageId, vectorId: recordId})
    await getStorageKnowledgeRecords({id: currentStorageId})
  }

  const isLoading = useMemo(() => {
    return getStorageKnowledgeRecordsQueryResult.isFetching ||
      deleteStorageKnowledgeRecordMutationResult.isLoading ||
      createStorageKnowledgeRecordMutationResult.isLoading
  }, [getStorageKnowledgeRecordsQueryResult, deleteStorageKnowledgeRecordMutationResult, createStorageKnowledgeRecordMutationResult])

  return (
    <div className="custom-separator-list-container">
      <div className="tab-header">
        <div className="header-description">
          <h2>Review All Data</h2>
          <p>Here you may find and edit all the data that has been added to your Knowledge Base.</p>
        </div>
        <TestChatButton />
      </div>
      <List
        pagination={{
          position: 'bottom',
          align: 'center',
          className: 'pagination',
        }}
        loading={isLoading}
        split={false}
        dataSource={getStorageKnowledgeRecordsQueryResult.data?.documents}
        renderItem={(item) => (
          <List.Item key={item.id}>
            <Divider className="list-item-divider" />
            <SearchResultItem item={item} handleDeleteItem={handleDeleteItem} handleEditData={handleEditData} />
          </List.Item>
        )}
      />
    </div>
  )
}

export default ReviewAllDataTab
