import { isArray } from 'lodash'
import { useEffect } from 'react'
import { notification } from 'antd'
import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { IS_PRODUCTION } from '../config/consts'

const normalizeErrorData = (errorData: any) => {
  if (!IS_PRODUCTION) {
    console.log('ERROR DATA', errorData)
  }
  try {
    if (errorData?.data?.exception) {
      const result = JSON.parse(errorData.data.exception)
      if (isArray(result?.message)) {
        return errorData?.data?.message + ', ' + result.message.join(', ')
      }
      return result?.message
    }
    if (errorData?.data?.message) {
      return errorData.data.message
    }
    if (errorData?.error?.data?.message) {
      return errorData.error.data.message
    }
    if (errorData?.error && typeof errorData?.error === 'string') {
      return errorData?.error
    }
  } catch (err) {
    console.log('NORMALIZE ERROR DATA:', err)
  }
  return 'Oops! Something went wrong!'
}

export const useErrorMessage = (message: string, error: FetchBaseQueryError | SerializedError | string | undefined) => {
  useEffect(() => {
    if (error && typeof error !== 'string') {
      const messageNew = normalizeErrorData(error) || message
      notification.error({
        message: messageNew,
      })
    } else if (typeof error === 'string') {
      notification.error({
        message: error,
      })
    }
  }, [error])
}
