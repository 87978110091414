import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Col, Row, Space, Tooltip } from 'antd'
import { CheckCircleTwoTone, QuestionCircleTwoTone } from '@ant-design/icons'

import { RolesEnum } from '../../config/rolesEnum'
import { useAppSelector } from '../../hooks/appHook'
import { useErrorMessage } from '../../hooks/useErrorMessage'
import { useGetAccountQuery } from '../../store/api/account.api'
import { useLazyResendCodeQuery } from '../../store/api/auth.api'
import { useSuccessMessage } from '../../hooks/useSuccessMessage'

import './profile.less'

const Profile = () => {
  const { user } = useAppSelector((state) => state.profile)
  const { account } = useAppSelector((state) => state.account)

  useGetAccountQuery(undefined, { refetchOnMountOrArgChange: true })
  const [resendCode, { error, status }] = useLazyResendCodeQuery()

  useSuccessMessage('Email with verification instructions was sent.', status === 'fulfilled')
  useErrorMessage('Something went wrong', error)

  const handleVerifyButtonClick = () => {
    resendCode({ email: user.email })
  }

  return (
    <div className="profile-wrapper">
      <div>
        <h2>Account Profile</h2>
        <Row className="row">
          <Col span={24} sm={12}>
            Account name:
          </Col>
          <Col span={24} sm={12}>
            {account.name ? account.name : '-'}
          </Col>
        </Row>
        {user.role === RolesEnum.ADMIN && (
          <>
            <Row className="row">
              <Col span={24} sm={12}>
                Account status:
              </Col>
              <Col span={24} sm={12}>
                {account.status ? account.status : '-'}
              </Col>
            </Row>
          </>
        )}
      </div>

      <div>
        <h2>My Profile</h2>
        <Row className="row">
          <Col span={24} sm={12}>
            Your name:
          </Col>
          <Col span={24} sm={12}>
            {user.name}
          </Col>
        </Row>
        <Row className="row">
          <Col span={24} sm={12}>
            Email:
          </Col>
          <Col span={24} sm={12}>
            <Space>
              {user.email}
              <Tooltip placement="bottom" title={'Your email is ' + user.status}>
                {user.status === 'verified' ? (
                  <CheckCircleTwoTone twoToneColor="#52c41a" />
                ) : (
                  <QuestionCircleTwoTone twoToneColor="#FF7625FF" />
                )}
              </Tooltip>
              {user.status !== 'verified' && (
                <Button loading={status === 'pending'} onClick={handleVerifyButtonClick}>
                  Resend verification email
                </Button>
              )}
            </Space>
          </Col>
        </Row>
        <Row className="row">
          <Col span={12}></Col>
          <Col span={12}>
            <Link className='edit-btn' to="edit">Edit...</Link>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Profile
