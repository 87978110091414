import {createApi} from "@reduxjs/toolkit/query/react";
import {ITicket} from "../../types/ticket.type";
import baseQueryWithReauth from "../baseQueryWithReauth";

interface IGetTickets {
	limit?: number,
	offset?: number,
	orderBy?: string,
	orderDirection?: string,
	filter?: string
}

export const ticketApi = createApi({
	reducerPath: "ticketApi",
	baseQuery: baseQueryWithReauth,
	endpoints: (builder) => ({
		getTickets: builder.query<{ tickets: ITicket[], count: number }, IGetTickets>({
			query: (params) => ({
				url: `ticket/`,
				method: "GET",
				params,
			}),
		}),
		getTicket: builder.query<ITicket, string>({
			query: (id) => ({
				url: `ticket/` + id,
				method: "GET",
			}),
		}),
		ticketUpdate: builder.mutation<ITicket, Partial<ITicket>>({
			query: (body) => ({
				url: `ticket/` + body.id,
				method: "PUT",
				body
			}),
		}),
	}),
});


export const {
	useGetTicketQuery,
	useGetTicketsQuery,
	useLazyGetTicketsQuery,
	useTicketUpdateMutation,
} = ticketApi;
