import React, {FC} from "react";
import {Button, ButtonProps} from "antd";

import "./appSecondaryButton.less";

const AppSecondaryButton: FC<ButtonProps> = ({children, ...props}) => {

  return (
    <Button
      id="app-secondary-button"
      {...props}
    >
      {children}
    </Button>
  );
};

export default AppSecondaryButton;
