import React from 'react'
import { useLocation } from 'react-router-dom'
import { AI_HELPER_ROUTE, AI_HELPER_UPDATE_DATA_ROUTE } from '../../config/consts'
import AiHelperIntegrateAccountToAi from './AiHelperIntegrateAccountToAi'

const SiderMenuFooter = () => {
  const location = useLocation()

  const header = [
    {
      path: AI_HELPER_ROUTE,
      render: <AiHelperIntegrateAccountToAi />,
    },
    {
      path: AI_HELPER_UPDATE_DATA_ROUTE,
      render: <AiHelperIntegrateAccountToAi />,
    },
  ]

  return <>{header.find((item) => location.pathname.includes(item.path))?.render}</>
}

export default SiderMenuFooter
