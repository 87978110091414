import React, { memo } from 'react'
import { Form, FormInstance, Input, InputNumber } from 'antd'
import { requiredValidator } from '../../../../helpers/validators'
import { InfoCircleOutlined } from '@ant-design/icons'
import LanguageSelect from '../../../../components/LanguageSelect/LanguageSelect'
import { EditStorageRequest } from '../../../../types/storage.type'
import './ceStorageForm.less'

interface IProps {
  storageForm: FormInstance
  language: string
  setLanguage: (lang: string) => void
  onSubmitForm: (values: EditStorageRequest) => void
  reverse?: boolean
}
const CEStorageForm = ({ storageForm, language, setLanguage, onSubmitForm, reverse }: IProps) => {
  return (
    <Form
      name="create_edit_storage_form"
      form={storageForm}
      layout="vertical"
      className="create-storage-form"
      style={{ padding: '20px 0' }}
      onFinish={onSubmitForm}
    >
      <div style={{ order: reverse ? 1 : 0 }} className="create-storage-form-main-settings">
        {reverse ? <h2>Other Settings </h2> : null}
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, validator: requiredValidator('Name is required!') }]}
          style={{ marginBottom: '8px' }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Distance Threshold"
          name="distanceThreshold"
          tooltip={{
            title:
              'How similar data should be used in searhc results. The bigger number the less similar data will be fetched. Should be a number between 0 and 10',
            icon: <InfoCircleOutlined />,
          }}
          style={{ marginBottom: '8px' }}
        >
          <InputNumber style={{ width: '100%' }} min={0} max={10} />
        </Form.Item>
        <Form.Item
          label="Results quantity"
          name="resultsQuantity"
          tooltip={{
            title:
              'How many results should we use when search for data. Should be a number between 0 and 40. By defautl - 3.',
            icon: <InfoCircleOutlined />,
          }}
          style={{ marginBottom: '8px' }}
        >
          <InputNumber style={{ width: '100%' }} min={0} max={40} />
        </Form.Item>
      </div>
      <div style={{ order: reverse ? 0 : 1 }}>
        <Form.Item label={!reverse && 'Language'} name="language">
          <LanguageSelect initialLang={language} setLanguage={setLanguage} />
        </Form.Item>
      </div>
    </Form>
  )
}

export default memo(CEStorageForm)
