import jwtDecode from 'jwt-decode'
import { createSlice } from '@reduxjs/toolkit'
import { LocalStorage } from 'ts-localstorage'
import { authApi } from '../api/auth.api'
import { usersApi } from '../api/users.api'
import { RolesEnum, AccountRolesEnum } from '../../config/rolesEnum'
import { IUser, UserState } from '../../types/profile.type'
import { ACCESS_TOKEN, REFRESH_TOKEN } from '../../config/consts'
import { accountApi } from '../api/account.api'

const accessToken = <string>LocalStorage.getItem(ACCESS_TOKEN)
const initUser = accessToken ? jwtDecode<IUser>(accessToken) : { id: '', role: RolesEnum.USER, accountId: '' }

export const initialState: UserState = {
  user: {
    id: initUser.id,
    role: initUser.role,
    accountId: initUser.accountId,
    name: '',
    email: '',
    picture: '',
    status: '',
    accountRole: AccountRolesEnum.USER,
  },
}

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload
    },
    tokenReceived: (state, action) => {
      state.user = jwtDecode<IUser>(action.payload.accessToken)
      LocalStorage.setItem(ACCESS_TOKEN, action.payload.accessToken)
      LocalStorage.setItem(REFRESH_TOKEN, action.payload.refreshToken)
    },
    userResetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(authApi.endpoints.signIn.matchFulfilled, (state, action) => {
        state.user = jwtDecode<IUser>(action.payload.accessToken)
        LocalStorage.setItem(ACCESS_TOKEN, action.payload.accessToken)
        LocalStorage.setItem(REFRESH_TOKEN, action.payload.refreshToken)
      })
      .addMatcher(authApi.endpoints.signUp.matchFulfilled, (state, action) => {
        state.user = jwtDecode<IUser>(action.payload.accessToken)
        LocalStorage.setItem(ACCESS_TOKEN, action.payload.accessToken)
        LocalStorage.setItem(REFRESH_TOKEN, action.payload.refreshToken)
      })
      .addMatcher(usersApi.endpoints.inviteCreateSession.matchFulfilled, (state, action) => {
        state.user = jwtDecode<IUser>(action.payload.accessToken)
        LocalStorage.setItem(ACCESS_TOKEN, action.payload.accessToken)
        LocalStorage.setItem(REFRESH_TOKEN, action.payload.refreshToken)
      })
      .addMatcher(accountApi.endpoints.getAccount.matchFulfilled, (state, action: any) => {
        state.user = { ...state.user, ...action.payload.user }
      })
  },
})

export const { tokenReceived, userResetState } = profileSlice.actions
export const userActionCreators = profileSlice.actions

export default profileSlice.reducer
