import { createSlice } from '@reduxjs/toolkit'
import { AgentsState } from '../../types/agent.type'
import { agentApi } from '../api/agent.api'

export const initialState: AgentsState = {
  agents: [],
  currentAgentId: '',
  agent: null
}

export const agentsSlice = createSlice({
  name: 'agents',
  initialState,
  reducers: {
    setAgentsList: (state, action) => {
      state.agents = action.payload
    },
    setCurrentAgentId: (state, action) => {
      state.currentAgentId = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(agentApi.endpoints?.getAgentList.matchFulfilled, (state, action) => {
      state.agents = action.payload
    })
    builder.addMatcher(agentApi.endpoints?.getAgentById.matchFulfilled, (state, action) => {
      state.agent = action.payload
    })
  },
})

export const {
  setAgentsList,
  setCurrentAgentId
} = agentsSlice.actions

export default agentsSlice.reducer
