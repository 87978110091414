import {LocalStorage} from "ts-localstorage";
import {createApi} from "@reduxjs/toolkit/query/react";
import {ITicket} from "../../types/ticket.type";
import {ACCESS_TOKEN} from "../../config/consts";
import {IMessage} from "../../types/message.type";
import baseQueryWithReauth from "../baseQueryWithReauth";

export const messageApi = createApi({
    reducerPath: 'messageApi',
    baseQuery:baseQueryWithReauth,
    endpoints: (builder) => ({
        getMessages: builder.query<IMessage[], { limit?: number, offset?: number, ticketId: number }>({
            query: (params) => ({
                url: `message/`,
                method: 'GET',
                params,
                credentials: 'include',
                mode: 'cors',
                headers: {
                    'authorization': 'Bearer '+ <string>LocalStorage.getItem(ACCESS_TOKEN),
                }
            }),
        }),
        getMessage: builder.query<ITicket, string>({
            query: (id) => ({
                url: `message/`+ id,
                method: 'GET',
                credentials: 'include',
                mode: 'cors',
                headers: {
                    'authorization': 'Bearer '+ <string>LocalStorage.getItem(ACCESS_TOKEN),
                }
            }),
        }),

        messageUpdate: builder.mutation<ITicket, Partial<ITicket>>({
            query: (args) => ({
                url: `message/`+ args.id,
                method: 'PUT',
                credentials: 'include',
                mode: 'cors',
                body: args,
                headers: {
                    'authorization': 'Bearer '+ <string>LocalStorage.getItem(ACCESS_TOKEN),
                }
            }),
        }),
    }),
})


export const {useLazyGetMessagesQuery} = messageApi
