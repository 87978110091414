import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ACCESS_TOKEN, API_URL } from '../../config/consts'
import { Message } from '../../types/storage.type'
import { LocalStorage } from 'ts-localstorage'
import { ChatConfigurations, GetChatRequest } from '../../types/public.type'

export const publicApi = createApi({
  reducerPath: 'publicApi',
  baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
  endpoints: (builder) => ({
    chat: builder.query<{id: string, messages: Array<Message>}, GetChatRequest>({
      query: (body) => ({
        url: `public/chat`,
        method: 'POST',
        body,
      }),
    }),
    getChatConfig: builder.query<ChatConfigurations, {id: string, agentId?: string}>({
      query: ({id, agentId}) => ({
        url: `chat-settings/${id}${agentId ? `?agentId=${agentId}` : ''}`,
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
        headers: {
          authorization: 'Bearer ' + <string>LocalStorage.getItem(ACCESS_TOKEN),
        },
      })
    })
  }),
})

export const {
  useLazyChatQuery,
} = publicApi
