import { message } from 'antd'
import React, { useEffect } from 'react'
import Icon from '../UI/Icons/Icons'
import { useAppSelector } from '../../hooks/appHook'
import AppTooltip from '../UI/AppTooltip/AppTooltip'
import { useErrorMessage } from '../../hooks/useErrorMessage'
import { useAccountActions, useAppStateActions } from '../../hooks/actions'
import AppSecondaryButton from '../UI/AppSecondaryButton/AppSecondaryButton'
import AppSmallButtonWhite from '../UI/AppSmallButtonWhite/AppSmallButtonWhite'
import { useAccountUpdateMutation } from '../../store/api/account.api'
import { AccountStatusEnum } from '../../types/account.type'

const AiHelperIntegrateAccountToAi = () => {
  const [messageApi, contextHolder] = message.useMessage()
  const { setIntegrationActionMade } = useAccountActions()
  const { setIsIntegrationTooltipOpen } = useAppStateActions()

  const { user } = useAppSelector((state) => state.profile)
  const { account } = useAppSelector((state) => state.account)
  const { isIntegrationTooltipOpen } = useAppSelector((state) => state.appState.modals)
  const { isIntegrationActionMade } = useAppSelector((state) => state.account)

  const [accountUpdate, { error: errorAccountUpdate, status: accountUpdateStatus }] = useAccountUpdateMutation()

  const handleIntegrateAccountToAi = () => {
    accountUpdate({ data: { applyForIntegration: true } })
  }

  useErrorMessage('Something went wrong', errorAccountUpdate)

  const showSuccessIntegrationPopup = () => {
    messageApi.open({
      content: (
        <div className="integration-popup-text-wrapper">
          <h2>
            Thank you <span className="primary-color">{user.name}</span>
          </h2>
          <p>
            We appreciate your interest in joining our system.
            <br />
            We will reach out to you shortly to proceed with the integration steps.
          </p>
        </div>
      ),
      duration: 5,
    })
  }

  useEffect(() => {
    if (accountUpdateStatus === 'fulfilled') {
      showSuccessIntegrationPopup()
      setIntegrationActionMade(true)
      setIsIntegrationTooltipOpen(false)
    }
  }, [accountUpdateStatus])

  if (account.status !== AccountStatusEnum.EMPTY) {
    return null
  }

  return (
    <>
      {contextHolder}
      <AppTooltip
        open={isIntegrationTooltipOpen}
        overlayClassName="integrate-data-tooltip"
        placement="top"
        title={
          <div>
            <span>
              {isIntegrationActionMade
                ? 'We already received your notification. We will reach out to you shortly to proceed with the integration steps.'
                : 'We may integrate your own data and/or knowledge base with Ioni App so you may test how it works with real data.'}
            </span>
            <AppSmallButtonWhite onClick={() => setIsIntegrationTooltipOpen(false)}>Okay</AppSmallButtonWhite>
          </div>
        }
      >
        <AppSecondaryButton
          loading={accountUpdateStatus === 'pending'}
          onClick={handleIntegrateAccountToAi}
          disabled={isIntegrationActionMade}
          onMouseOver={() => setIsIntegrationTooltipOpen(true)}
        >
          <Icon name="dataFlow" />
          Integrate My Data
        </AppSecondaryButton>
      </AppTooltip>
    </>
  )
}

export default AiHelperIntegrateAccountToAi
