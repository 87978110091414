import { createApi } from '@reduxjs/toolkit/query/react'
import baseQueryWithReauth from '../baseQueryWithReauth'
import { IInviteSessionSetPassword, IUpdateProfileInfoRequest, IUser } from '../../types/profile.type'
import { accountApi } from './account.api'
import { usersApi } from './users.api'

export const profileApi = createApi({
  reducerPath: 'profileApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    updateProfileInfo: builder.mutation<IUser, Partial<IUpdateProfileInfoRequest>>({
      query: (body) => ({
        url: `users`,
        method: 'PATCH',
        body,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          console.error('Error on profile info updating!');
        } finally {
          dispatch(accountApi.util.invalidateTags(['Account']));
        }
      }
    }),
    deleteProfile: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `users/${id}`,
        method: 'DELETE',
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled
        dispatch(usersApi.util.invalidateTags([{ type: 'GetUsers' }]))
      },
    }),
    inviteSessionAndSetPassword: builder.mutation<IUser, IInviteSessionSetPassword>({
      query: (args) => ({
        url: `user/${args.id}`,
        method: 'PUT',
        body: args,
      }),
    }),
  }),
})

export const {
  useUpdateProfileInfoMutation,
  useDeleteProfileMutation,
  useInviteSessionAndSetPasswordMutation
} = profileApi
