import React, {memo} from 'react'
import { Button, ButtonProps } from 'antd'
import './transparentButton.less'

const TransparentButton = ({ onClick, title, ...props }: ButtonProps) => {
  return (
    <Button id="transparent-button" onClick={onClick} {...props}>
      {title}
    </Button>
  )
}

export default memo(TransparentButton);
