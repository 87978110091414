import React from "react";
import {Tooltip, TooltipProps} from "antd";

import "./appTooltip.less";
import {theme} from "../../../theme/theme";

const AppTooltip = (props: TooltipProps) => {
  return (
    <Tooltip
      {...props}
      color={theme.token.colorPrimary}
      id="app-tooltip"
    >
      {props.children}
    </Tooltip>
  );
};

export default AppTooltip;
