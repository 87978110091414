import React from 'react'
import { Row, Col, Card, Statistic, Table, Tooltip } from 'antd'
import { Line } from 'react-chartjs-2'
import 'chart.js/auto'
import { LikeOutlined, SendOutlined, SearchOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { ConversationStats } from '../../types/conversation.type'
import { Link } from 'react-router-dom'
import moment from 'moment'

const colorByScore = (score: number | undefined) => {
  if (!score) {
    return undefined
  }
  if (score < 5) {
    return 'red'
  }
  if (score < 7.5) {
    return '#fa8c16'
  }
  return 'green'
}

const columns = [
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (value: string, record: any) => {
      const formatDate = moment(value).format('D/MM/YYYY')
      const formatTime = moment(value).format('h:mm a')
      return (
        <Link to={`conversations/${record.conversationId}`} className="conversation-url">
          {formatDate} <span>{formatTime}</span>
        </Link>
      )
    },
  },
  {
    title: 'Missing Data',
    dataIndex: 'data',
    key: 'data',
  },
]

const Statistics = ({ data }: { data: ConversationStats }) => {
  return (
    <div className="container" style={{ maxWidth: 900 }}>
      <Row gutter={16}>
        <Col span={8}>
          <Card bordered={false}>
            <Statistic
              title={
                <>
                  Total conversations{' '}
                  <Tooltip title="We analyse conversations with at least one reply and only in 6 hours after last reply.">
                    <InfoCircleOutlined />
                  </Tooltip>
                </>
              }
              value={data.count}
              precision={0}
              prefix={<SendOutlined />}
              suffix="/ week"
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card bordered={false}>
            <Statistic
              title={
                <>
                  Satisfactory{' '}
                  <Tooltip title="This score shows how satisified was the used after conversation with chat agent. This data is provided by our Analytics AI Agent.">
                    <InfoCircleOutlined />
                  </Tooltip>
                </>
              }
              value={data.averageSatisfactory}
              precision={1}
              valueStyle={{ color: colorByScore(data.averageSatisfactory) }}
              prefix={<LikeOutlined />}
              suffix="/ 10"
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card bordered={false}>
            <Statistic
              title={
                <>
                  Info found{' '}
                  <Tooltip title="This score shows how good was chat agent in finding relevant information that user asked for. This data is provided by our Analytics AI Agent. If this score is too low, it make sense to add more data into your knowledge base.">
                    <InfoCircleOutlined />
                  </Tooltip>
                </>
              }
              value={data.averageInfoFounded}
              prefix={<SearchOutlined />}
              precision={1}
              valueStyle={{ color: colorByScore(data.averageInfoFounded) }}
              suffix="/ 10"
            />
          </Card>
        </Col>
      </Row>
      <Line
        style={{ margin: '30px 0' }}
        data={{
          datasets: [
            {
              label: 'Satisfactory',
              data: data.chart?.map((d) => ({
                x: d.date,
                y: d.averageSatisfactory,
              })),
            },
            {
              label: 'Info Found',
              data: data.chart?.map((d) => ({
                x: d.date,
                y: d.averageInfoFounded,
              })),
            },
          ],
        }}
        options={{
          scales: {
            y: { min: 0 },
          },
        }}
      />
      <h2>
        Questions with no answer{' '}
        <Tooltip title="Here you can see a list of queries that your agent was not able to answer for a last week.">
          <InfoCircleOutlined />
        </Tooltip>
      </h2>
      <Table dataSource={data.missingData} columns={columns} pagination={false} />
    </div>
  )
}

export default Statistics
