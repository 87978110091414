import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import { tokenReceived } from './slices/profile.slice'
import { Mutex } from 'async-mutex'
import { ACCESS_TOKEN, ADMIN_ACCOUNT_ID, ADMIN_USER_ID, API_URL, REFRESH_TOKEN } from '../config/consts'
import { LocalStorage } from 'ts-localstorage'

// create a new mutex
const mutex = new Mutex()
const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  mode: 'cors',
  credentials: 'include',
  prepareHeaders: (headers) => {
    const token = LocalStorage.getItem(ACCESS_TOKEN)
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }

    const adminUserId = LocalStorage.getItem(ADMIN_USER_ID)
    const adminAccountId = LocalStorage.getItem(ADMIN_ACCOUNT_ID)

    if (adminUserId && adminAccountId) {
      headers.set('admin-user-id', adminUserId)
      headers.set('admin-account-id', adminAccountId)
    }
    return headers
  },
})
const refreshQuery = fetchBaseQuery({
  baseUrl: API_URL,
  method: 'POST',
  credentials: 'include',
})

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock()
  let result = await baseQuery(args, api, extraOptions)
  if (result.error && result.meta?.response?.status === 401) {
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire()
      try {
        const refreshResult = await refreshQuery(
          {
            url: '/auth/refresh',
            body: { refreshToken: <string>LocalStorage.getItem(REFRESH_TOKEN) },
          },
          api,
          extraOptions,
        )
        if (refreshResult.data) {
          api.dispatch(tokenReceived(refreshResult.data))
          // retry the initial query
          result = await baseQuery(args, api, extraOptions)
        } else {
          LocalStorage.clear()
          window.location.reload()
        }
      } finally {
        // release must be called once the mutex should be released again.
        release()
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock()
      result = await baseQuery(args, api, extraOptions)
    }
  }
  return result
}

export default baseQueryWithReauth
