import React from "react";
import {Spin} from "antd";

const Loading = () => {
    return (
        <Spin size={"large"}/>
    );
};

export default Loading;
