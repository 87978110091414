import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Checkbox, Form, Input, Space } from 'antd'
import { AccountRolesEnum } from '../../config/rolesEnum'
import { IAccount } from '../../types/account.type'
import { PROFILE_ROUTE } from '../../config/consts'
import { useAppSelector } from '../../hooks/appHook'
import { useErrorMessage } from '../../hooks/useErrorMessage'
import { useUpdateProfileInfoMutation } from '../../store/api/profile.api'
import { useSuccessMessage } from '../../hooks/useSuccessMessage'
import { useAccountUpdateMutation } from '../../store/api/account.api'
import './editProfile.less'

import './profile.less'
import Loading from '../../components/UI/Loading'
import { minLengthValidator, requiredValidator } from '../../helpers/validators'
import { omit } from 'lodash'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
}

const ProfileEdit = () => {
  const navigate = useNavigate()

  const [formAccount] = Form.useForm()
  const [formProfile] = Form.useForm()

  const { user } = useAppSelector((state) => state.profile)
  const { account } = useAppSelector((state) => state.account)
  const [isPasswordChange, setIsPasswordChange] = useState<boolean>(false)
  const [isEmailChange, setIsEmailChange] = useState<boolean>(false)
  const [
    userUpdate,
    { data: userUpdateData, error: errorUserUpdate, isSuccess: isUserSuccess, isLoading: isUpdatingUser },
  ] = useUpdateProfileInfoMutation()
  const [accountUpdate, { error: errorAccountUpdate, isSuccess: isAccountSuccess, isLoading: isUpdatingAccount }] =
    useAccountUpdateMutation()

  const onFinishAccount = (values: IAccount) => {
    accountUpdate({ ...values })
  }

  const onFinishProfile = (values: { name: string; email: string; password: string; currentPassword: string }) => {
    userUpdate({ ...values })
  }

  useErrorMessage(`Can't update record`, errorUserUpdate || errorAccountUpdate)
  useSuccessMessage('Successfully updated', isAccountSuccess)
  useSuccessMessage(
    `Successfully updated${
      (userUpdateData as any)?.isEmailVerificationSent ? '. Verification link sent to new email' : ''
    }`,
    isUserSuccess,
  )

  useEffect(() => {
    if (isUserSuccess || isAccountSuccess) {
      navigate(PROFILE_ROUTE)
    }
  }, [isUserSuccess, isAccountSuccess])

  if (!user.name) {
    return (
      <div id="loading-container">
        <Loading />
      </div>
    )
  }

  return (
    <div className="profile-wrapper">
      {user.accountRole === AccountRolesEnum.ADMIN && (
        <>
          <h2>Account</h2>
          <Form {...formItemLayout} form={formAccount} initialValues={account} onFinish={onFinishAccount}>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, validator: requiredValidator('Please input account name!') }]}
            >
              <Input />
            </Form.Item>
            {/*<Form.Item*/}
            {/*	name="apiKey"*/}
            {/*	label="API Key"*/}
            {/*	//rules={[{ required: true, message: "Please input account name!", whitespace: true }]}*/}
            {/*>*/}
            {/*	<Input/>*/}
            {/*</Form.Item>*/}
            {/*<Form.Item*/}
            {/*	name="domain"*/}
            {/*	label="Domain"*/}
            {/*	//rules={[{ required: true, message: "Please input account name!", whitespace: true }]}*/}
            {/*>*/}
            {/*	<Input suffix={".freshdesk.com"}/>*/}
            {/*</Form.Item>*/}
            {/*<Form.Item*/}
            {/*	name="webHookApiKey"*/}
            {/*	label="WebHook API Key"*/}
            {/*	//rules={[{ required: true, message: "Please input account name!", whitespace: true }]}*/}
            {/*>*/}
            {/*	<Input disabled/>*/}
            {/*</Form.Item>*/}

            <Form.Item {...tailFormItemLayout}>
              <Space>
                <Button type="primary" loading={isUpdatingAccount} htmlType="submit">
                  Save account information
                </Button>
                <Button onClick={() => navigate(PROFILE_ROUTE)}>Back to profile</Button>
              </Space>
            </Form.Item>
          </Form>
        </>
      )}
      <h2>Profile</h2>
      <Form {...formItemLayout} form={formProfile} initialValues={omit(user, 'email')} onFinish={onFinishProfile}>
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: 'Please input your name!',
              whitespace: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item name="passwordEmail" valuePropName="checked" {...tailFormItemLayout}>
          <Checkbox
            checked={isEmailChange}
            onClick={() => {
              setIsEmailChange((prevState) => !prevState)
              setIsPasswordChange(false)
              formProfile.resetFields(['passwordChange'])
            }}
          >
            Change email
          </Checkbox>
        </Form.Item>
        <Form.Item name="passwordChange" valuePropName="checked" {...tailFormItemLayout}>
          <Checkbox
            checked={isPasswordChange}
            onClick={() => {
              setIsPasswordChange((prevState) => !prevState)
              setIsEmailChange(false)
              formProfile.resetFields(['passwordEmail'])
            }}
          >
            Change password
          </Checkbox>
        </Form.Item>

        {isEmailChange && (
          <>
            <Form.Item
              name="email"
              label="New e-mail"
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
                {
                  required: true,
                  message: 'Please input your E-mail!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </>
        )}

        {(isEmailChange || isPasswordChange) && (
          <Form.Item
            name="currentPassword"
            label="Current password"
            rules={[{ required: true, validator: requiredValidator('Current password is required!') }]}
          >
            <Input.Password />
          </Form.Item>
        )}

        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) =>
            isPasswordChange && (
              <>
                <Form.Item
                  name="password"
                  label="New password"
                  hasFeedback={!!getFieldValue('password')}
                  rules={[
                    { validator: minLengthValidator(8, 'Password') },
                    { required: true, validator: requiredValidator('Password is required!') },
                  ]}
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item
                  name="confirmPassword"
                  label="Confirm new password"
                  dependencies={['password']}
                  hasFeedback={!!getFieldValue('confirmPassword')}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve()
                        }
                        return Promise.reject(new Error('The two passwords that you entered do not match!'))
                      },
                    }),
                    { required: true, validator: requiredValidator('Confirm new password is required!') },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              </>
            )
          }
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Space>
            <Button type="primary" loading={isUpdatingUser} htmlType="submit">
              Save profile information
            </Button>
            <Button onClick={() => navigate(PROFILE_ROUTE)}>Back to profile</Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  )
}

export default ProfileEdit
