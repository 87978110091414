import React, { useCallback, useState, memo } from 'react'
import { Input, Modal } from 'antd'
import { useAppSelector } from '../../../hooks/appHook'
import { useDispatch } from 'react-redux'
import { useAppStateActions } from '../../../hooks/actions'

interface IProps {
  handleImageLinkAdd: (link: string) => void
}

const AddImageByLinkModal = ({handleImageLinkAdd}: IProps) => {
  const dispatch = useDispatch()

  /** Store */
  const {isAddImageByUrlModalOpen} = useAppSelector(state => state.appState.modals)

  /** State */
  const [imageLink, setImageLink] = useState('')

  /** Use custom hooks */
  const { setAddImageByUrlModalOpen } = useAppStateActions()

  const onPressOk = useCallback(() => {
    if (handleImageLinkAdd) {
      handleImageLinkAdd(imageLink)
      setImageLink('')
      dispatch(setAddImageByUrlModalOpen(false))
    }
  }, [handleImageLinkAdd, setAddImageByUrlModalOpen])

  const onPressCancel = useCallback(() => {
    setImageLink('')
    dispatch(setAddImageByUrlModalOpen(false))
  }, [setAddImageByUrlModalOpen])

  return <Modal
    title="Add Image by Link"
    open={isAddImageByUrlModalOpen}
    onOk={onPressOk}
    onCancel={onPressCancel}
  >
    <Input
      value={imageLink}
      onChange={(e) => setImageLink(e.target.value)}
      placeholder="Enter image link"
    />
    {imageLink && <img src={imageLink} alt="Preview" style={{ width: '100%', height: 'auto', marginTop: 10 }} />}
  </Modal>
}

export default memo(AddImageByLinkModal)
