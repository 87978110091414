import React, { ReactElement, useEffect, useState } from 'react'
import './applicationMenu.less'
import { Button, List } from 'antd'
import { useNavigate } from 'react-router-dom'

type MenuItem = { label: ReactElement; key: string; icon: ReactElement; path: string; title: string }

interface Props {
  items: Array<MenuItem>
}

const ApplicationMenu = ({ items }: Props) => {
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)

  const onChooseItem = (path: string) => {
    navigate(path)
    setIsOpen(false)
  }

  const renderItem = (item: MenuItem) => (
    <Button className="menu-item" onClick={() => onChooseItem(item.path)}>
      {item.icon}
      {item.title}
    </Button>
  )

  useEffect(() => {
    const handleDocumentClick = (event: MouseEvent) => {
      const clickedElement = event.target as HTMLElement
      if (isOpen && !clickedElement?.closest('.app-menu-container')) {
        setIsOpen(false)
      }
    }

    document.addEventListener('click', handleDocumentClick)

    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [isOpen])

  return (
    <div className={isOpen ? 'app-menu-container active' : 'app-menu-container'}>
      <button className="app-menu-close" onClick={() => setIsOpen((p) => !p)}>
        <h1 className="app-menu-title">
          My Account Menu <span className="arrow"></span>
        </h1>
      </button>
      <div className="divider" />
      <div className="app-menu-open">
        <List dataSource={items} renderItem={renderItem} />
      </div>
    </div>
  )
}

export default ApplicationMenu
