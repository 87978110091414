import React from 'react'
import { useDispatch } from 'react-redux'
import { LocalStorage } from 'ts-localstorage'
import { useNavigate } from 'react-router-dom'
import Logo from '../../components/UI/Logos/Logo'
import TransparentButton from '../../components/UI/TransparentButton/TransparentButton'
import { userResetState } from '../../store/slices/profile.slice'
import { accountApi } from '../../store/api/account.api'
import { ADMIN_ACCOUNT_ID, ADMIN_USER_ID, SIGNIN_ROUTE, SIGNUP_ROUTE } from '../../config/consts'

interface Props {
  isSignup?: boolean
}

const TopFloater = ({ isSignup }: Props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleAuth = () => {
    const adminUserId = LocalStorage.getItem(ADMIN_USER_ID)
    const adminAccountId = LocalStorage.getItem(ADMIN_ACCOUNT_ID)

    if (adminAccountId || adminUserId) {
      LocalStorage.removeItem(ADMIN_USER_ID)
      LocalStorage.removeItem(ADMIN_ACCOUNT_ID)
    } else {
      LocalStorage.clear()
    }
    dispatch(userResetState())
    dispatch(accountApi.util.resetApiState())

    if (!isSignup) {
      return navigate(SIGNUP_ROUTE)
    }

    return navigate(SIGNIN_ROUTE)
  }

  return (
    <div className="top-floater">
      <a href="http://ioni.ai" target="_blank" className="logo-container" rel="noreferrer">
        <Logo name={'ioniBlack'} width={'100%'} height={'100%'} />
      </a>
      <div style={{ display: 'none' }}>
        <div className="top-floater-right">
          <div className="top-floater-right-text">
            {isSignup ? 'Already have an account?' : "Don't have an account yet?"}
          </div>
          <TransparentButton title={isSignup ? 'Sign In' : 'Sign Up'} onClick={handleAuth} />
        </div>
      </div>
    </div>
  )
}

export default TopFloater
