import React from 'react'
import TopFloater from './TopFloater'
import '../../layouts/guestLayout/guestLayout.less'

interface Props {
  isSignup?: boolean
}

const NotFound = ({ isSignup }: Props) => {
  return (
    <>
      <TopFloater isSignup={isSignup} />
      <div className="login-box">404 - Not found</div>
    </>
  )
}

export default NotFound
