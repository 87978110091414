import React, { useMemo, memo } from 'react'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import { Message } from '../../../../types/storage.type'
import { ConversationContentItem } from '../../../../types/conversation.type'
import CustomMessage from '../CustomMessage/CustomMessage'

interface IProps {
  message: Message
}

const ReactMarkdownContent = ({ message }: IProps) => {
  const isIncludesImages = useMemo(() => {
    if (Array.isArray(message.content)) {
      return !!message.content?.find((el: ConversationContentItem) => el.type === 'image_url')
    }
    return false
  }, [message])

  return (
    <div id={message.role + '-paragraph-container'}>
      <h4>{message.role === 'tool' ? 'function response' : message.role}</h4>
      {message.tool_calls?.length ? (
        <div className="paragraph function-call-paragraph">
          <h5>function_call {message.tool_calls[0].function.name}</h5>
          <p> ({message.tool_calls[0].function?.arguments})</p>
        </div>
      ) : (
        <p className={`paragraph ${isIncludesImages && 'paragraph-images'}`} id={'paragraph-' + message.role}>
          {message.content && (
            <ReactMarkdown
              components={{
                a: ({ href, children }) => (
                  <a href={href} target="_blank" rel="noopener noreferrer">
                    {children}
                  </a>
                ),
                p: ({ children }) => {
                  return <CustomMessage message={message}>{children}</CustomMessage>
                },
                code: ({ children, inline }) => {
                  if (inline) {
                    return <code className="language-js">{children}</code>
                  }
                  return (
                    <pre>
                      <code className="language-js">{children}</code>
                    </pre>
                  )
                },
                ul: ({ children }) => {
                  return <ul>{children}</ul>
                },
                ol: ({ children }) => {
                  return <ol>{children}</ol>
                },
                li: ({ children }) => {
                  return <li>{children}</li>
                },
              }}
            >
              {Array.isArray(message.content) ? JSON.stringify(message.content) : message.content}
            </ReactMarkdown>
          )}
        </p>
      )}
    </div>
  )
}

export default memo(ReactMarkdownContent)
