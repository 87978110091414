import React, { ChangeEvent, ClipboardEvent, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ConfigProvider, Divider, Form, Input, List } from 'antd'
import AppPrimaryButton from '../../../components/UI/AppPrimaryButton/AppPrimaryButton'
import SearchResultItem from '../../../components/UI/AISearchResultItem/AISearchResultItem'
import { useAppSelector } from '../../../hooks/appHook'
import { useErrorMessage } from '../../../hooks/useErrorMessage'
import { useSuccessMessage } from '../../../hooks/useSuccessMessage'
import { useWarningMessage } from '../../../hooks/useWarningMessage'
import {requiredValidator} from "../../../helpers/validators";
import {
  useCreateStorageKnowledgeRecordByStorageIdMutation,
  useDeleteStorageKnowledgeRecordByIdMutation,
} from '../../../store/api/storage.api'
import {
  setAddedKnowledgeRecords,
  setEditedKnowledgeRecordId,
  setEditedKnowledgeRecords, setKnowledgeRecords, setIsSuccess
} from '../../../store/slices/storage.slice'
import { StorageKnowledgeRecord } from '../../../types/storage.type'

const AddHtmlOrTextTab = () => {
  const dispatch = useDispatch()

  const [formAddData] = Form.useForm()

  /** Storage */
  const {
    editedKnowledgeRecords,
    editedKnowledgeRecordId,
    addedKnowledgeRecords,
    currentStorageId
  } = useAppSelector((state) => state.storage)

  /** Storage Actions */
  const [
    createStorageKnowledgeRecord,
    createStorageKnowledgeRecordMutationResult
  ] = useCreateStorageKnowledgeRecordByStorageIdMutation()
  const [
    deleteStorageKnowledgeRecord,
    deleteStorageKnowledgeRecordMutationResult
  ] = useDeleteStorageKnowledgeRecordByIdMutation()

  /** State */
  const [newStorageKnowledgeRecord, setNewStorageKnowledgeRecord] = useState<string>('')

  /** Response message handlers */
  useErrorMessage('Something went wrong', createStorageKnowledgeRecordMutationResult.error)
  useSuccessMessage(
    'You data was successfully added. Please check below and adjust if needed.',
    createStorageKnowledgeRecordMutationResult.isSuccess && !!createStorageKnowledgeRecordMutationResult.data?.documents?.length,
  )
  useWarningMessage(
    'Seems like we were not able to parse your content. Please try again with more data.',
    createStorageKnowledgeRecordMutationResult.isSuccess && !createStorageKnowledgeRecordMutationResult.data?.documents?.length,
  )

  const handlePaste = (event: ClipboardEvent<HTMLTextAreaElement>) => {
    event.preventDefault()

    const regExp = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/
    const pastedPlainText = event.clipboardData.getData('text/plain')
    const concatValue = newStorageKnowledgeRecord.concat(pastedPlainText)

    formAddData.setFieldsValue({ data: concatValue })

    let requestValue = concatValue

    if (regExp.test(pastedPlainText)) {
      requestValue = newStorageKnowledgeRecord.concat(pastedPlainText)
    }

    setNewStorageKnowledgeRecord(requestValue)
  }

  const onFinishDataUpdate = async () => {
    await createStorageKnowledgeRecord({
      id: currentStorageId,
      body: {data: newStorageKnowledgeRecord}
    })

    setNewStorageKnowledgeRecord('')
  }

  const handleDeleteItem = async (item: StorageKnowledgeRecord) => {
    await deleteStorageKnowledgeRecord({
      id: currentStorageId,
      vectorId: item.id}
    )

    const filteredDataSource = addedKnowledgeRecords
      ?.filter((record) => record.id !== item.id)

    filteredDataSource && dispatch(setAddedKnowledgeRecords(filteredDataSource))
  }

  const handleEditData = async (value: string, id: string) => {
    await createStorageKnowledgeRecord(
      {
        id: currentStorageId,
        body: { singleRecord: true, metadata: { source: 'edit_data_ui' }, data: value }
      })
    await deleteStorageKnowledgeRecord({id: currentStorageId, vectorId: id})
    dispatch(setEditedKnowledgeRecordId(id))
  }

  useEffect(() => {
    if (editedKnowledgeRecordId && editedKnowledgeRecords[0]) {
      const index = addedKnowledgeRecords?.findIndex((record) => record.id === editedKnowledgeRecordId)
      if (typeof index === 'number' && index > -1 && addedKnowledgeRecords) {
        const updatedData = [...addedKnowledgeRecords]
        updatedData[index] = editedKnowledgeRecords[0]
        dispatch(setAddedKnowledgeRecords(updatedData))
      }
    }
  }, [editedKnowledgeRecordId, editedKnowledgeRecords])

  useEffect(() => {
    if (createStorageKnowledgeRecordMutationResult.status === 'fulfilled') {
      formAddData.resetFields()
    }
  }, [createStorageKnowledgeRecordMutationResult.status])

  useEffect(() => {
    return () => {
      dispatch(setAddedKnowledgeRecords([]))
      dispatch(setEditedKnowledgeRecords([]))
      dispatch(setEditedKnowledgeRecordId(''))
      dispatch(setKnowledgeRecords([]))
      dispatch(setIsSuccess(false))
    }
  }, [])

  const onKnowledgeRecordChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setNewStorageKnowledgeRecord(e.target.value)
  }, [])

  return (
    <Form
      layout="vertical"
      requiredMark={false}
      form={formAddData}
      onFinish={onFinishDataUpdate}
      disabled={createStorageKnowledgeRecordMutationResult.status === 'pending'}
    >
      <div className="form-ai-helper-col-wrapper form-ai-helper-col-wrapper-align-left">
        <h3>Paste HTML or text here</h3>
        <Form.Item
          name="data"
          rules={[{ min: 10, message: 'Too short data. Please paste at least 10 characters.' }, {required: true, validator: requiredValidator('Data is Required!')}]}
        >
          <Input.TextArea
            autoFocus={true}
            id="form-input"
            autoSize={{ minRows: 6, maxRows: 6 }}
            onPaste={handlePaste}
            onChange={onKnowledgeRecordChange}
          />
        </Form.Item>
        <div className="search-buttons-container">
          <Form.Item>
            <AppPrimaryButton
              type="primary"
              size="middle"
              htmlType="submit"
              className="app-primary-button-medium"
              loading={createStorageKnowledgeRecordMutationResult.status === 'pending'}
            >
              Upload Data
            </AppPrimaryButton>
          </Form.Item>
        </div>
        <div className="custom-separator-list-container">
          <ConfigProvider renderEmpty={() => <></>}>
            <List
              pagination={{
                position: 'bottom',
                align: 'center',
                className: 'pagination',
              }}
              loading={createStorageKnowledgeRecordMutationResult.isLoading || deleteStorageKnowledgeRecordMutationResult.isLoading}
              split={false}
              dataSource={addedKnowledgeRecords}
              renderItem={(item) => (
                <List.Item key={item.id}>
                  <Divider className="list-item-divider" />
                  <SearchResultItem item={item} handleDeleteItem={handleDeleteItem} handleEditData={handleEditData} />
                </List.Item>
              )}
            ></List>
          </ConfigProvider>
        </div>
      </div>
    </Form>
  )
}

export default AddHtmlOrTextTab
