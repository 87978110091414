import React from "react";
import {Button, ButtonProps} from "antd";

import "./appSmallButtonWhite.less";

const AppSmallButtonWhite = (props: ButtonProps) => {
  return (
    <Button
      {...props}
      id="app-small-button-white"
    >
      {props.children}
    </Button>
  );
};

export default AppSmallButtonWhite;
