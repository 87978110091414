import React from 'react'
import { Link } from 'react-router-dom'
import { LocalStorage } from 'ts-localstorage'
import { Dropdown, MenuProps, Space } from 'antd'
import Icon from '../UI/Icons/Icons'
import { useAppSelector } from '../../hooks/appHook'
import { LogoutOutlined, ProfileOutlined, TeamOutlined } from '@ant-design/icons'
import './accountMenu.less'
import { ADMIN_ACCOUNT_ID, ADMIN_USER_ID } from '../../config/consts'

const AccountMenu = () => {
  const { user } = useAppSelector((state) => state.profile)

  const logout = () => {
    const adminUserId = LocalStorage.getItem(ADMIN_USER_ID)
    const adminAccountId = LocalStorage.getItem(ADMIN_ACCOUNT_ID)

    if (adminAccountId || adminUserId) {
      LocalStorage.removeItem(ADMIN_USER_ID)
      LocalStorage.removeItem(ADMIN_ACCOUNT_ID)
    } else {
      LocalStorage.clear()
    }
    window.location.reload()
  }

  const items: MenuProps['items'] = [
    {
      label: (
        <div>
          <div className="account-menu-username">{user.name}</div>
          <div className="account-menu-email">{user.email}</div>
        </div>
      ),
      key: 'User',
    },
    {
      type: 'divider',
    },
    {
      label: (
        <Link to="profile">
          <Space>
            <ProfileOutlined />
            My Profile
          </Space>
        </Link>
      ),
      key: 'MyProfile',
    },
    {
      label: (
        <Link to="team">
          <Space>
            <TeamOutlined />
            Team
          </Space>
        </Link>
      ),
      key: 'Team',
    },
    {
      type: 'divider',
    },
    {
      label: (
        <div onClick={() => logout()}>
          <Space>
            <LogoutOutlined />
            Logout
          </Space>
        </div>
      ),
      key: 'Logout',
    },
  ]

  return (
    <Dropdown
      menu={{ items }}
      trigger={['click']}
      placement={'bottomRight'}
      //onOpenChange={(open) => setOpen(open)}
    >
      <div className="account-menu-title">
        <Icon name="accountMenu" />
        {user.name}
        <Icon name="arrowDown" />
      </div>
    </Dropdown>
  )
}

export default AccountMenu
