import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IOnboarding, OnboardingState } from '../../types/onboarding.type'
import { LOGO_URL } from '../../config/consts'

export const cxmSystems = [
  {
    title: 'Freshdesk',
    image: LOGO_URL + 'freshdesk.png',
  },
  {
    title: 'Zendesk',
    image: LOGO_URL + 'zendesk.svg',
  },
  {
    title: 'Slack',
    image: LOGO_URL + 'slack.svg',
  },
  {
    title: 'Jira',
    image: LOGO_URL + 'jira.png',
  },
  {
    title: 'SalesForce',
    image: LOGO_URL + 'salesforce.png',
  },
  {
    title: 'Hubspot',
    image: LOGO_URL + 'hubspot.png',
  },
  {
    title: 'Intercom',
    image: LOGO_URL + 'intercom.svg',
  },
  {
    title: 'Whatsapp',
    image: LOGO_URL + 'whatsapp.png',
  },
]

export const operatorValues = [
  {
    value: '1 - 5',
    label: '1 - 5',
  },
  {
    value: '6 - 10',
    label: '6 - 10',
  },
  {
    value: '11 - 20',
    label: '11 - 50',
  },
  {
    value: 'more than 50',
    label: 'more than 50',
  },
]

export const initialState: OnboardingState = {
  onboarding: {
    cxmCustomSystem: '',
    cxmSystem: {
      title: '',
      image: '',
    },
    numberOfOperators: '1 - 5',
    name: '',
    domain: '',
  },
}

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setCxmSystem: (state, action: PayloadAction<IOnboarding['cxmSystem']>) => {
      state.onboarding.cxmSystem = action.payload
    },
    setCxmCustomSystem: (state, action: PayloadAction<IOnboarding['cxmCustomSystem']>) => {
      state.onboarding.cxmCustomSystem = action.payload
    },
    setNumberOfOperators: (state, action: PayloadAction<string>) => {
      state.onboarding.numberOfOperators = action.payload
    },
    setName: (state, action: PayloadAction<string>) => {
      state.onboarding.name = action.payload
    },
    setDomain: (state, action: PayloadAction<string>) => {
      state.onboarding.domain = action.payload
    },
    resetState: () => initialState,
  },
})

export const onboardingActionCreators = onboardingSlice.actions
export default onboardingSlice.reducer
