import React,  { useEffect } from 'react'
import _, { isEmpty } from 'lodash'
import { Alert, Layout, Menu, Space, Spin } from 'antd'
import { Link, Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { BulbOutlined } from '@ant-design/icons'
import LeftHeader from './LeftHeader'
import RightHeader from './RightHeader'
import Logo from '../../components/UI/Logos/Logo'
import Icon from '../../components/UI/Icons/Icons'
import { useAppSelector } from '../../hooks/appHook'
import { AccountStatusEnum } from '../../types/account.type'
import { useGetAccountQuery } from '../../store/api/account.api'
import AccountMenu from '../../components/AccountMenu/AccountMenu'
import InviteToTeamModal from '../../components/UI/Modals/InviteToTeamModal'
import SiderMenuFooter from '../../components/SiderMenuFooter/SiderMenuFooter'
import { RolesEnum } from '../../config/rolesEnum'
import {
  ACCOUNTS,
  ADMIN_USER_ID,
  CONVERSATIONS,
  KNOWLEDGE_BASE,
  ONBOARDING_ROUTE,
  SIGNIN_ROUTE,
} from '../../config/consts'
import ApplicationMenu from '../../components/ApplicationMenu/ApplicationMenu'
import { LocalStorage } from 'ts-localstorage'
import './mainLayout.less'


const { Header, Content, Sider } = Layout



const MainLayout = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const adminUserId = LocalStorage.getItem(ADMIN_USER_ID)

  /** Storage */
  const { user } = useAppSelector((state) => state.profile)
  const { account } = useAppSelector((state) => state.account)
  const { data, isLoading } = useGetAccountQuery(undefined, { skip: !user.id })

 // const { currentAgentId } = useAppSelector((state) => state.agents)

  const sliderMenuItems = [
    {
      label: <Link to="storage">Knowledge Base</Link>,
      key: '/storage',
      icon: <Icon name="addData" width={23} height={23} />,
      path: KNOWLEDGE_BASE,
      title: 'Knowledge Base',
    },
    // {
    //   label: <Link to="agent">AI Agent</Link>,
    //   key: '/agent',
    //   icon: <Icon name="createAssistent" />,
    //   path: CREATE_AI_ASSISTENT,
    //   title: 'Create AI chatbot',
    // },
    {
      label: <Link to="conversations">Conversations</Link>,
      key: '/conversations',
      icon: <Icon name="conversations" />,
      path: CONVERSATIONS,
      title: 'See conversations',
    },
  ]

  const adminItems = [
    {
      label: <Link to="admin/accounts">Accounts</Link>,
      key: 'admin/accounts',
      icon: <BulbOutlined />,
      path: ACCOUNTS,
      title: 'Accounts',
    },
  ]

  const chatItem = (account?.id  ? [{
    label: <Link to={`/chat`}>Safety inspection</Link>,
    key: `/chat`,
    icon: <Icon name="chat" />,
    path: `/chat`,
    title: 'Safety inspection',
  }] : [])


  useEffect(() => {
    document.body.style.overflow = 'auto'
  }, [])

  useEffect(() => {
    if (isEmpty(data)) {
      return
    }

    const isOnboarding = account.status === AccountStatusEnum.ONBOARD

    if (isOnboarding && location.pathname !== ONBOARDING_ROUTE) {
      return navigate(ONBOARDING_ROUTE)
    }
  }, [data, account])

  if (!user?.id) {
    return <Navigate to={SIGNIN_ROUTE} state={{ from: location }} />
  }

  const menuItems = user?.role == RolesEnum.ADMIN
    ? [...sliderMenuItems,  ...chatItem, ...adminItems]
    : [...sliderMenuItems, ...chatItem];

  return (
    <div>
      <div className="bg-shape" />
      <div className="bg-shape" />
      <div className="bg-shape" />
      <div className="bg-shape" />
      <Spin spinning={isLoading}>
        <Layout id="ioni-main-layout">
          <InviteToTeamModal />
          <Sider width={274} id="ioni-layout-sider" breakpoint="lg" collapsedWidth="0" className="ioni-layout-sider">
            <Link to="/" className="logo">
              <Logo name="safetyLensDefault" />
            </Link>
            <Menu
              mode={'inline'}
              items={menuItems}
              selectedKeys={[location.pathname]}
            />
            <div className="sider-menu-footer">
              <SiderMenuFooter />
            </div>
          </Sider>
          <Layout>
            <Header>
              <div className="header">
                <div className="right-header-item">
                  <Space size={25}>
                    <div>
                      <Link to="/" className="logo">
                        <Logo name="safetyLensDefault" />
                      </Link>
                      <RightHeader />
                      <AccountMenu />
                    </div>
                  </Space>
                </div>
                {!!adminUserId && <p id="admin-account-email">{user.email}</p>}
                <ApplicationMenu
                  items={user?.role == RolesEnum.ADMIN ? _.union(sliderMenuItems, adminItems) : sliderMenuItems}
                />
                {user?.status === 'unverified' && (
                  <Alert
                    message={
                      <>
                        Please verify your email address {user.email} to use Ioni. Resend verification email on your{' '}
                        <Link to="/profile">profile page</Link>.
                      </>
                    }
                    type="warning"
                    style={{ marginTop: 20 }}
                  />
                )}
                <div className="left-header-item">
                  <LeftHeader />
                </div>
              </div>
            </Header>
            <Content className="content">
              <Outlet />
            </Content>
          </Layout>
        </Layout>
      </Spin>
    </div>
  )
}

export default MainLayout
