import {useDispatch} from "react-redux";
import {bindActionCreators} from "@reduxjs/toolkit";
import {userActionCreators} from "../store/slices/profile.slice";
import {adminActionCreators} from "../store/slices/users.slice";
import {ticketActionCreators} from "../store/slices/ticket.slice";
import {accountActionCreators} from "../store/slices/account.slice";
import {appStateActionCreators} from "../store/slices/appState.slice";
import {messageActionCreators} from "../store/slices/message.slice";
import {onboardingActionCreators} from "../store/slices/onboardingSlice";


export const useUserActions = () => {
    const dispatch = useDispatch()
    return bindActionCreators(userActionCreators, dispatch)
}

export const useAccountActions = () => {
    const dispatch = useDispatch()
    return bindActionCreators(accountActionCreators, dispatch)
}

export const useAdminActions = () => {
    const dispatch = useDispatch()
    return bindActionCreators(adminActionCreators, dispatch)
}

export const useAppStateActions = () => {
    const dispatch = useDispatch()
    return bindActionCreators(appStateActionCreators, dispatch)
}

export const useTicketActions = () => {
    const dispatch = useDispatch()
    return bindActionCreators(ticketActionCreators, dispatch)
}

export const useMessageActions = () => {
    const dispatch = useDispatch()
    return bindActionCreators(messageActionCreators, dispatch)
}

export const useOnboardingActions = () => {
    const dispatch = useDispatch()
    return bindActionCreators(onboardingActionCreators, dispatch)
}
